import apiUserProfile from '../../api/user/UserProfileAPI';
import regex from '../regex';

const id = async (id) => {
    const userProfile = await apiUserProfile.id(id);
    return userProfile;
};

const invalid = err => {
    return {
        ok: false,
        err: err
    }
};

const validate = async (userProfile) => {

    if (!userProfile.status || 'status' === `${userProfile.status}`.toLowerCase()) return invalid('Please select status');
    if (!userProfile.role || 'role' === `${userProfile.role}`.toLowerCase()) return invalid('Please select role');
    if (!userProfile.username) return invalid('Username is required');
    if (!userProfile.name) return invalid('Name is required');
    if (!userProfile.email) return invalid('Email is required');
    else if (!regex.isValidEmail(userProfile.email)) return invalid('Invalid email');
    if (!userProfile.mobile) return invalid('Mobile is required');
    else if (!regex.isValidPhone(userProfile.mobile)) return invalid('Invalid mobile');

    const uniqueUsername = await usernameIsUnique(userProfile._id, userProfile.username);
    if (!uniqueUsername) return invalid('Username already exists');
    const uniqueEmail = await emailIsUnique(userProfile._id, userProfile.email);
    if (!uniqueEmail) return invalid('Email already exists');

    return {
        ok: true
    };
};

const newId = async () => {
    const newId = await apiUserProfile.newId();
    return newId.id;
};

const save = async (userProfile) => {
    const updatedUserProfile = await apiUserProfile.save(userProfile);
    return updatedUserProfile;
};

const search = async (val, activeUserOnly, userLevel, currentLength) => {
    const userProfile = await apiUserProfile.search(val, activeUserOnly, userLevel, currentLength);
    return userProfile;
};

const countSearch = async (val, activeUserOnly, userLevel) => {
    const result = await apiUserProfile.countSearch(val, activeUserOnly, userLevel);
    let totalRecord = 0;
    if (result && result.length === 1) {
        totalRecord = result[0].total;
    }
    return totalRecord;
};

const login = async (username, password) => {
    const userProfile = await apiUserProfile.login(username, password);
    return userProfile;
};

const resetPassword = async (id) => {
    await apiUserProfile.resetPassword(id);
};

const validateLogin = (userProfile) => {
    if (!userProfile.username) return invalid('Username is required');
    if (!userProfile.password) return invalid('Password is required');

    return {
        ok: true
    };
};

const validateForgotPassword = (userProfile) => {
    if (!userProfile.email) return invalid('Email is required');
    else if (!regex.isValidEmail(userProfile.email)) return invalid('Invalid email');

    return {
        ok: true
    };
};

const forgotPassword = async (email) => {
    await apiUserProfile.forgotPassword(email);
};

const usernameIsUnique = async (id, username) => {
    const isUnique = await apiUserProfile.usernameIsUnique(id, username);
    return isUnique;
};

const emailIsUnique = async (id, email) => {
    const isUnique = await apiUserProfile.emailIsUnique(id, email);
    return isUnique;
};

export default {
    id: id,
    validate: validate,
    newId: newId,
    save: save,
    search: search,
    countSearch: countSearch,
    login: login,
    resetPassword: resetPassword,
    validateLogin: validateLogin,
    validateForgotPassword: validateForgotPassword,
    forgotPassword: forgotPassword,
    usernameIsUnique: usernameIsUnique,
    emailIsUnique: emailIsUnique,
};
