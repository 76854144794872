import AuthForm, { STATE_LOGIN, STATE_FORGOT } from 'components/AuthForm';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';

const AuthPage = props => {
  useEffect(() => {
    if (localStorage.getItem('isChecked') === 'false') {
      localStorage.clear()
    }
    if (props.match.params) {
      if (props.match.params.key) console.log(`key? ${props.match.params.key}`);
      else console.log(`no key`);
    }
    else console.log(`not params`);
  }, [])

  const handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      props.history.push('/');
    }
    else if (authState === STATE_FORGOT) {
      props.history.push('/forgotpassword');
    } else {
      props.history.push('/signup');
    }
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Col md={6} lg={4}>
        <Card body>
          <AuthForm
            authState={props.authState}
            onChangeAuthState={handleAuthState}
          // onLogoClick={this.handleLogoClick}
          />
        </Card>
      </Col>
    </Row>
  );

}

export default AuthPage;
