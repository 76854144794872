import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import allReducers from './redux/reducers';

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, allReducers);

//store
const store = createStore(persistedReducer,
    composeWithDevTools(applyMiddleware(thunk)));

// const store = createStore(persistedReducer,
//     /* preloadedState, */
//     (applyMiddleware(thunk)) &&
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// store.subscribe(() => {
//     const str = JSON.stringify(store.getState(), null, 4);
//     console.log(`redux.state=>`);
//     console.log(str);
// });
//store.subscribe(() => console.log(store.getState()));
const persistor = persistStore(store);

ReactDOM.render(
    // <React.StrictMode>
    <Suspense fallback={<span>Loading...</span>}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </Suspense>,
    // </React.StrictMode>,
    document.getElementById('root')
);

