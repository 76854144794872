import React, { useEffect, useState } from 'react';
import { STATE_LOGIN, STATE_SIGNUP, STATE_FORGOT } from './components/AuthForm';
import GAListener from './components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout, ProtectedRoute } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import AuthPage from './pages/AuthPage';
import AuthResetPage from './pages/AuthResetPage';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import versionComponent from '../src/biz/versionComponent';
import lib from 'biz/lib';
import { useSelector } from 'react-redux';

const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const AuditPage = React.lazy(() => import('pages/AuditPage'));
const DataSummaryReportPage = React.lazy(() => import('pages/DataSummaryReportPage'));
const BIPage = React.lazy(() => import('pages/BIPage'));
const BIPage2 = React.lazy(() => import('pages/BIPage2'));
const BIPage5 = React.lazy(() => import('pages/BIPage5'));
const PointPage = React.lazy(() => import('pages/PointPage'));
const AboutPage = React.lazy(() => import('pages/AboutPage'));
const AgentPage = React.lazy(() => import('pages/AgentPage'));
const AgentLitePage = React.lazy(() => import('pages/AgentLitePage'));
// const ServiceMerchantPage = React.lazy(() => import('pages/ServiceMerchantPage'));
const ServiceMerchantApplicationPage = React.lazy(() => import('pages/ServiceMerchantApplicationPage'));
const ServiceMerchantProfilePage = React.lazy(() => import('pages/ServiceMerchantProfilePage'));
const ServiceMerchantApplicationForm = React.lazy(() => import('forms/ServiceMerchantApplicationForm'));
const ServiceMerchantEnquiryForm = React.lazy(() => import('forms/ServiceMerchantEnquiryForm'));
// const ShopPage = React.lazy(() => import('pages/ShopPage'));
// const ItemPage = React.lazy(() => import('pages/ItemPage'));
const PropertyPage = React.lazy(() => import('pages/PropertyPage'));
const TopItemPage = React.lazy(() => import('pages/TopItemPage'));
const PromotionItemPage = React.lazy(() => import('pages/PromotionItemPage'));
const AppUserPage = React.lazy(() => import('pages/AppUserPage'));

const UserRolePage = React.lazy(() => import('pages/UserRolePage'));
const FixedPriceItemPage = React.lazy(() => import('pages/FixedPriceItemPage'));
const UserProfilePage = React.lazy(() => import('pages/UserProfilePage'));
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'));
const RedeemItemPage = React.lazy(() => import('pages/RedeemItemPage'));
const RedeemTransactionPage = React.lazy(() => import('pages/RedeemTransactionPage'));
const AgentBankPage = React.lazy(() => import('pages/AgentBankPage'));
const ChallengePage = React.lazy(() => import('pages/ChallengePage'));
const VideoPage = React.lazy(() => import('pages/VideoPage'));
const PropertyMasterPage = React.lazy(() => import('pages/PropertyMasterPage'));
const PropertyMasterDraftPage = React.lazy(() => import('pages/PropertyMasterDraftPage'));
const DeveloperPage = React.lazy(() => import('pages/DeveloperPage'));
const ProjectPage = React.lazy(() => import('pages/ProjectPage.js'));
const AgentTncPage = React.lazy(() => import('pages/AgentTncPage.js'));
const AuctionPropertyPage = React.lazy(() => import('pages/AuctionPropertyPage.js'));
const BidNowUserRolePage = React.lazy(() => import('pages/BidNowUserRolePage.js'));
const BidNowUserProfilePage = React.lazy(() => import('pages/BidNowUserProfilePage.js'));
const AgentRoleDraftPage = React.lazy(() => import('pages/AgentRoleDraftPage.js'));
//const TestPage = React.lazy(() => import('pages/TestPage.js'));
//const SpecialistPage = React.lazy(() => import('pages/_SpecialistPage'));
const AuctionProcessPage = React.lazy(() => import('pages/AuctionProcessPage'));
const BidderPage = React.lazy(() => import('pages/BidderPage.js'));
// const Workshop = React.lazy(() => import('pages/WorkshopPage.js'));
const Assessment = React.lazy(() => import('pages/AssessmentPage.js'));
const Attendance = React.lazy(() => import('pages//AttendancePage.js'));
const WorkshopPage = React.lazy(() => import('pages/WorkshopPage.js'));
const LuckyDraw = React.lazy(() => import('pages/LuckyDrawPage.js'));
const PrizeList = React.lazy(() => import('pages/PrizeListPage.js'));
//const CondoSpecialistPage = React.lazy(() => import('pages/_CondoSpecialistPage.js'));
const AdvertisementPage = React.lazy(() => import('pages/AdvertisementPage.js'));
const FeaturePropertyPage = React.lazy(() => import('pages/FeaturePropertyPage.js'));
const AnnouncementPage = React.lazy(() => import('pages/AnnouncementPage.js'));
//const AreaSpecialistPage = React.lazy(() => import('pages/_AreaSpecialistPage.js'));
// const ECardEditorPage = React.lazy(() => import('pages/ECardEditorPage.js'));
//const BranchAddressPage = React.lazy(() => import('pages/BranchAddressPage.js'))
const AgentApplicationPage = React.lazy(() => import('pages/AgentApplicationPage.js'));
const AuditTrailPage = React.lazy(() => import('pages/AuditTrailPage.js'));
const BidNowPage = React.lazy(() => import('pages/BidNowPage.js'));
const PaymentVoucherPage = React.lazy(() => import('pages/PaymentVoucherPage.js'));
const SaleQuotationPage = React.lazy(() => import('pages/SaleQuotationPage.js'));
const InvoicePage = React.lazy(() => import('pages/InvoicePage.js'));
const ReceiptPage = React.lazy(() => import('pages/ReceiptPage.js'));
const CoBrokePage = React.lazy(() => import('pages/CoBrokePage.js'));
const AreaMasterPage = React.lazy(() => import('pages/AreaMasterPage.js'));
const AgentPlottingPage = React.lazy(() => import('pages/AgentPlottingPage.js'));
const AgentFirm = React.lazy(() => import('pages/AgentFirmPage.js'));
const NewSpecialistPage = React.lazy(() => import('pages/NewSpecialistPage.js'));
const SpecialistOverviewPage = React.lazy(() => import('pages/AreaSpecialistOverviewPage.js'));
const FlagManagementPage = React.lazy(() => import('pages/FlagManagementPage.js'));
const WhereAreMyAgentsPage = React.lazy(() => import('pages/WhereAreMyAgentsPage'));
const AgentOnlineReportSummaryPage = React.lazy(() => import('pages/AgentOnlineReportSummaryPage.js'))
// const EnquiriesPage = React.lazy(() => import('pages/EnquiriesPage'))
const ServiceMerchantEnquiriesPage = React.lazy(() => import('pages/ServiceMerchantEnquiryPage'))
const AISearchPage = React.lazy(() => import('pages/AISearchPage'));
const SearchLogPage = React.lazy(() => import('pages/SearchLogPage'));
const PropertySummaryReportPage = React.lazy(() => import('pages/PropertySummaryReportPage'));
// const ReferralPage = React.lazy(() => import('pages/ReferralPage'));
const ListingMigrationPage = React.lazy(() => import('pages/ListingMigrationPage'));
const ListingMigrationLogPage = React.lazy(() => import('pages/ListingMigrationLogPage'));
const AppointmentPage = React.lazy(() => import('pages/AppointmentPage'));
const EnquiryReminderPage = React.lazy(() => import('pages/EnquiryReminderPage'));
const BdProfilePage = React.lazy(() => import('pages/BdProfilePage'));
const BdSummaryPage = React.lazy(() => import('pages/BdSummaryPage'));
const BdSetting = React.lazy(() => import('pages/BdSetting'));
const ShowcasePage = React.lazy(() => import('pages/ShowcasePage'));
const GroupListingOverviewPage = React.lazy(() => import('pages/GroupListingOverviewPage'));
const GroupListingApplicationPage = React.lazy(() => import('pages/GroupListingApplicationPage'));
const ViewListingPage = React.lazy(() => import('pages/ViewListingPage'));
const MeetingRoomPage = React.lazy(() => import('pages/MeetingRoomPage'));
const MeetingRoomApplicationPage = React.lazy(() => import('pages/MeetingRoomApplicationPage'));
const MeetingRoomCreateBooking = React.lazy(() => import('pages/MeetingRoomCreateBooking'));
const SSOMeetingRoomAppPage = React.lazy(() => import('pages/SSOMeetingRoomAppPage'));
const AgentPaymentPage = React.lazy(() => import('pages/AgentPaymentPage'));
const MergeMasterProperty = React.lazy(() => import('pages/MergeMasterProperty'));
const PaymentGatewayPlatform = React.lazy(() => import('pages/PaymentGatewayPlatform'));
const PaymentGatewayTransPg = React.lazy(() => import('pages/PaymentGatewayTransPg'));
const Erp213LogPage = React.lazy(() => import('pages/Erp213LogPage'));

const App = props => {
  const getBasename = () => {
    //return `/${process.env.PUBLIC_URL.split('/').pop()}`;
    return '/';
  };
  const user = useSelector(state => state.user);

  //console.log(user)
  // useEffect(() => {
  //   if (user._id) {
  //     let trimmedUser = {
  //       _id: user._id,
  //       userName: user.username,
  //       name: user.name,
  //       level: user.level,
  //       role: user.role
  //     };

  //     // localStorage.setItem('user', JSON.stringify(trimmedUser));
  //     // localStorage.setItem('userModules', JSON.stringify(user.modules));
  //     // localStorage.setItem('userModules', JSON.stringify(user.role.modules));
  //     // return true
  //   }
  // }, [user])

  const isLoggedIn = () => {
    return user?._id !== null;
    // lib.log('check logged in ..');
    // let loggedInUser = localStorage.getItem('user');
    // if (loggedInUser) {
    //   loggedInUser = JSON.parse(loggedInUser);
    //   lib.log(loggedInUser);
    //   lib.log(loggedInUser._id);
    //   if (loggedInUser._id) {
    //     lib.log(`loggedIn: true`);
    //     if (user._id) {
    //       return true;
    //     }
    //     else {
    //       return false
    //     }

    //   }
    //   else return false;
    // }

    // else return false;
  };

  // const getUserModules = () => {
  //   let loggedInUserModules = localStorage.getItem('userModules');
  //   if (loggedInUserModules && loggedInUserModules !== undefined) {
  //     return JSON.parse(loggedInUserModules);
  //   }
  //   else return [];
  // };

  // let userModules = getUserModules()

  const checkVersion = async () => {
    const currentVersion = process.env.REACT_APP_VERSION
    const latestVersion = await versionComponent.getCurrentVersion()
    // lib.log("currentVersion")
    // lib.log(currentVersion)
    // lib.log("latestVersion")
    // lib.log(latestVersion.admin)
    if (latestVersion.admin !== currentVersion) {
      lib.log("window.reload(true)")
      window.location.reload(true)
    }
  }

  useEffect(() => {
    window.addEventListener('focus', checkVersion);
  }, [])

  return (
    <BrowserRouter basename={getBasename()}>
      <GAListener>
        <Switch>
          <LayoutRoute
            exact
            path="/"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_LOGIN} />
            )}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          />

          <LayoutRoute
            exact
            path="/forgotpassword"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_FORGOT} />
            )}
          />

          <LayoutRoute
            exact
            path="/reset/:key"
            layout={EmptyLayout}
            component={props => (
              <AuthResetPage {...props} />
            )}
          />

          <LayoutRoute
            exact
            path="/ssomeetingroom/:key"
            layout={EmptyLayout}
            component={props => (
              <SSOMeetingRoomAppPage {...props} />
            )}
          />

          {isLoggedIn() ?
            <MainLayout breakpoint={props.breakpoint} loggedInUserModules={user.modules}>
              <React.Suspense fallback={<PageSpinner />}>
                <Switch>
                  <Route exact path="/about" component={props => <AboutPage {...props} />} />
                  <Route exact path="/dashboard" component={props => <DashboardPage {...props} />} />
                  <Route exact path="/audit" component={props => <AuditPage {...props} />} />
                  {/* <Route exact path="/bi" component={BIPage} />
                    <Route exact path="/bi2" component={BIPage2} />
                    <Route exact path="/bi5" component={BIPage5} /> */}
                  <Route exact path="/bi3" component={() => {
                    window.open('https://charts.mongodb.com/charts-propadv---sit-mwpst/public/dashboards/5ffd1bb9-2917-4784-8502-06c5ead5cc5f');
                    return null;
                  }} />
                  <Route exact path="/bi4" component={() => {
                    window.open('https://charts.mongodb.com/charts-production-wfqkh/public/dashboards/5ffe669c-31e9-47e1-8f8b-0db810a84838');
                    return null;
                  }} />
                  <Route exact path="/erp213/log" component={Erp213LogPage} />

                  <ProtectedRoute exact path="/bi" component={BIPage} moduleName="bipages" userModules={user.modules} />
                  <ProtectedRoute exact path="/bi2" component={BIPage2} moduleName="bipages" userModules={user.modules} />
                  <ProtectedRoute exact path="/bi5" component={BIPage5} moduleName="bipages" userModules={user.modules} />
                  <ProtectedRoute exact path="/classic/agents" component={AgentPage} moduleName="agents" userModules={user.modules} />
                  <ProtectedRoute exact path="/agents" component={AgentLitePage} moduleName="agents" userModules={user.modules} />
                  {/* <ProtectedRoute exact path="/merchants" component={ServiceMerchantPage} moduleName="service merchants" userModules={user.modules} /> */}
                  <ProtectedRoute exact path="/merchants/profile" component={ServiceMerchantProfilePage} moduleName="service merchants" userModules={user.modules} />
                  <ProtectedRoute exact path="/merchants/application" component={ServiceMerchantApplicationPage} moduleName="service merchants" userModules={user.modules} />
                  <ProtectedRoute exact path="/merchants/form/:id" component={ServiceMerchantApplicationForm} moduleName="service merchants" userModules={user.modules} />
                  <ProtectedRoute exact path="/enquiry/form/:id" component={ServiceMerchantEnquiryForm} moduleName="service merchants" userModules={user.modules} />
                  {/* <ProtectedRoute exact path="/shops" component={ShopPage} moduleName="shops" userModules={userModules} /> */}
                  {/* <ProtectedRoute exact path="/products" component={ItemPage} moduleName="items" userModules={userModules} /> */}
                  <ProtectedRoute exact path="/products/top" component={TopItemPage} moduleName="items" userModules={user.modules} />
                  <ProtectedRoute exact path="/products/promotion" component={PromotionItemPage} moduleName="items" userModules={user.modules} />
                  <ProtectedRoute exact path="/products/fixedPrice" component={FixedPriceItemPage} moduleName="items" userModules={user.modules} />
                  <ProtectedRoute exact path="/properties" component={PropertyPage} moduleName="properties" userModules={user.modules} />
                  <ProtectedRoute exact path="/appusers" component={AppUserPage} moduleName="app users" userModules={user.modules} />
                  <ProtectedRoute exact path="/roles" component={UserRolePage} moduleName="roles" userModules={user.modules} />
                  <ProtectedRoute exact path="/userprofiles" component={UserProfilePage} moduleName="users" userModules={user.modules} />
                  <ProtectedRoute exact path="/audittrails" component={AuditTrailPage} moduleName="audittrails" userModules={user.modules} />
                  <ProtectedRoute exact path="/rewarditems" component={RedeemItemPage} moduleName="redeem items" userModules={user.modules} />
                  <ProtectedRoute exact path="/redemption" component={RedeemTransactionPage} moduleName="redeem transactions" userModules={user.modules} />
                  <ProtectedRoute exact path="/agent/banks" component={AgentBankPage} moduleName="agents" userModules={user.modules} />
                  <ProtectedRoute exact path="/agent/point" component={PointPage} moduleName="challenge" userModules={user.modules} />
                  <ProtectedRoute exact path="/challenges" component={ChallengePage} moduleName="challenge" userModules={user.modules} />
                  <ProtectedRoute exact path="/videos" component={VideoPage} moduleName="properties" userModules={user.modules} />
                  <ProtectedRoute exact path="/master/property" component={PropertyMasterPage} moduleName="property master" userModules={user.modules} />
                  <ProtectedRoute exact path="/agents/master/property" component={PropertyMasterDraftPage} moduleName="property master" userModules={user.modules} />
                  <ProtectedRoute exact path="/mergeMasterProperty" component={MergeMasterProperty} moduleName="property master" userModules={user.modules} />
                  <ProtectedRoute exact path="/developers" component={DeveloperPage} moduleName="developers" userModules={user.modules} />
                  <ProtectedRoute exact path="/projects" component={ProjectPage} moduleName="projects" userModules={user.modules} />
                  <ProtectedRoute exact path="/agents/tnc" component={AgentTncPage} moduleName="agents" userModules={user.modules} />
                  <ProtectedRoute exact path="/bidnow/auction/properties" component={AuctionPropertyPage} moduleName="auction" userModules={user.modules} />
                  <ProtectedRoute exact path="/bidnow/roles" component={BidNowUserRolePage} moduleName="BidNow user roles" userModules={user.modules} />
                  <ProtectedRoute exact path="/bidnow/userprofiles" component={BidNowUserProfilePage} moduleName="BidNow users" userModules={user.modules} />
                  <ProtectedRoute exact path="/agents/role" component={AgentRoleDraftPage} moduleName="agents" userModules={user.modules} />
                  {/* <ProtectedRoute exact path="/agents/specialists" component={SpecialistPage} moduleName='specialists' userModules={userModules} /> */}
                  <ProtectedRoute exact path="/bidnow/auction/process" component={AuctionProcessPage} moduleName="auction" userModules={user.modules} />
                  <ProtectedRoute exact path="/bidnow/auction/bidders" component={BidderPage} moduleName="bidders" userModules={user.modules} />
                  <ProtectedRoute exact path="/assessment" component={Assessment} moduleName="assessment" userModules={user.modules} />
                  <ProtectedRoute exact path="/attendance" component={Attendance} moduleName="assessment" userModules={user.modules} />
                  <ProtectedRoute exact path="/guidance" component={WorkshopPage} moduleName="workshop" userModules={user.modules} />
                  <ProtectedRoute exact path="/luckydraw" component={LuckyDraw} moduleName="luckyDraw" userModules={user.modules} />
                  <ProtectedRoute exact path="/luckydrawprize" component={PrizeList} moduleName="luckyDraw" userModules={user.modules} />
                  {/* <ProtectedRoute exact path="/condospecialists" component={CondoSpecialistPage} moduleName="condo specialists" userModules={userModules} /> */}
                  <ProtectedRoute exact path="/advertisements" component={AdvertisementPage} moduleName="advertisements" userModules={user.modules} />
                  <ProtectedRoute exact path="/feature/property" component={FeaturePropertyPage} moduleName="feature properties" userModules={user.modules} />
                  <ProtectedRoute exact path="/announcement" component={AnnouncementPage} moduleName="announcement" userModules={user.modules} />
                  {/* <ProtectedRoute exact path="/areaspecialists" component={AreaSpecialistPage} moduleName="area specialists" userModules={user.modules} /> */}
                  {/* <ProtectedRoute exact path="/ecardeditor" component={ECardEditorPage} moduleName="agents" userModules={userModules} /> */}
                  {/* <ProtectedRoute exact path="/branchaddress" component={BranchAddressPage} moduleName="agents" userModules={userModules} /> */}
                  <ProtectedRoute exact path="/agentapplication" component={AgentApplicationPage} moduleName="agent application" userModules={user.modules} />
                  <ProtectedRoute exact path="/bidnow" component={BidNowPage} moduleName="auction" userModules={user.modules} />
                  <ProtectedRoute exact path="/paymentvoucher" component={PaymentVoucherPage} moduleName="payment voucher" userModules={user.modules} />
                  <ProtectedRoute exact path="/salequotation" component={SaleQuotationPage} moduleName="sale quotation" userModules={user.modules} />
                  <ProtectedRoute exact path="/invoice" component={InvoicePage} moduleName="invoice" userModules={user.modules} />
                  <ProtectedRoute exact path="/receipt" component={ReceiptPage} moduleName="receipt" userModules={user.modules} />
                  <ProtectedRoute exact path="/cobroke" component={CoBrokePage} moduleName="cobroke" userModules={user.modules} />
                  <ProtectedRoute exact path="/areamaster" component={AreaMasterPage} moduleName="area master" userModules={user.modules} />
                  <ProtectedRoute exact path="/agentplotting" component={AgentPlottingPage} moduleName="agent plotting" userModules={user.modules} />
                  <ProtectedRoute exact path="/company" component={AgentFirm} moduleName="company" userModules={user.modules} />
                  {/* <ProtectedRoute exact path="/newspecialists" component={NewSpecialistPage} moduleName="agents" userModules={userModules} /> */}
                  <ProtectedRoute exact path="/flagmanagement" component={FlagManagementPage} moduleName="flag management" userModules={user.modules} />
                  <ProtectedRoute exact path="/specialists" component={NewSpecialistPage} moduleName="newspecialists" userModules={user.modules} />
                  <ProtectedRoute exact path="/areaspecialistsoverview" component={SpecialistOverviewPage} moduleName="area specialists overview" userModules={user.modules} />
                  <ProtectedRoute exact path="/whereareagents" component={WhereAreMyAgentsPage} moduleName="wherearemyagents" userModules={user.modules} />
                  <ProtectedRoute exact path="/agentonlinereport" component={AgentOnlineReportSummaryPage} moduleName="agent online report" userModules={user.modules} />
                  {/* <ProtectedRoute exact path="/enquiry" component={EnquiriesPage} moduleName="service merchants" userModules={user.modules} /> */}
                  <ProtectedRoute exact path="/enquiry" component={ServiceMerchantEnquiriesPage} moduleName="service merchants" userModules={user.modules} />
                  <ProtectedRoute exact path="/datasummaryreport" component={DataSummaryReportPage} moduleName="data summary report" userModules={user.modules} />
                  <ProtectedRoute exact path="/aisearch" component={AISearchPage} moduleName="aisearch" userModules={user.modules} />
                  <ProtectedRoute exact path="/searchlog" component={SearchLogPage} moduleName="audit" userModules={user.modules} />
                  <ProtectedRoute exact path="/propertysummaryreport" component={PropertySummaryReportPage} moduleName="audit" userModules={user.modules} />
                  {/* <ProtectedRoute exact path="/referrals" component={ReferralPage} moduleName="service merchants" userModules={user.modules} /> */}
                  <ProtectedRoute exact path="/listingmigration" component={ListingMigrationPage} moduleName="listing migration" userModules={user.modules} />
                  <ProtectedRoute exact path="/listingmigrationLog" component={ListingMigrationLogPage} moduleName="listing migration" userModules={user.modules} />
                  <ProtectedRoute exact path="/appointment" component={AppointmentPage} moduleName="appointment" userModules={user.modules} />
                  <ProtectedRoute exact path="/enquiryReminder" component={EnquiryReminderPage} moduleName="enquiry reminder" userModules={user.modules} />
                  <ProtectedRoute exact path="/bdProfile" component={BdProfilePage} moduleName="bd management" userModules={user.modules} />
                  <ProtectedRoute exact path="/bdSummary" component={BdSummaryPage} moduleName="bd management" userModules={user.modules} />
                  <ProtectedRoute exact path="/bdSetting" component={BdSetting} moduleName="bd setting" userModules={user.modules} />
                  <ProtectedRoute exact path="/showcase" component={ShowcasePage} moduleName="showcase" userModules={user.modules} />
                  <ProtectedRoute exact path="/grouplisting" component={GroupListingOverviewPage} moduleName="grouplisting" userModules={user.modules} />
                  <ProtectedRoute exact path="/grouplistingapplication" component={GroupListingApplicationPage} moduleName="grouplisting" userModules={user.modules} />
                  <ProtectedRoute exact path="/viewlisting" component={ViewListingPage} moduleName="grouplisting" userModules={user.modules} />
                  <ProtectedRoute exact path="/meetingroom" component={MeetingRoomPage} moduleName="meeting room" userModules={user.modules} />
                  <ProtectedRoute exact path="/meetingroomapplication" component={MeetingRoomApplicationPage} moduleName="meeting room" userModules={user.modules} />
                  <ProtectedRoute exact path="/meetingroom/createbooking" component={MeetingRoomCreateBooking} moduleName="meeting room" userModules={user.modules} />
                  <ProtectedRoute exact path="/agentpayment" component={AgentPaymentPage} moduleName="agent payment" userModules={user.modules} />
                  <ProtectedRoute exact path="/paymentgateway/platform" component={PaymentGatewayPlatform} moduleName="payment gateway" userModules={user.modules} />
                  <ProtectedRoute exact path="/paymentgateway/transactions" component={PaymentGatewayTransPg} moduleName="payment gateway" userModules={user.modules} />
                  <Route component={props => <NotFoundPage {...props} />} />
                </Switch>
              </React.Suspense>
            </MainLayout> :
            <Redirect to="/" />
          }
        </Switch>
      </GAListener>
    </BrowserRouter>
  );
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
