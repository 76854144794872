import { Content, Footer, Header, Sidebar } from 'components/Layout';
import React, { useEffect } from 'react';
import {
  MdImportantDevices,
  // MdCardGiftcard,
  //MdLoyalty,
} from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

const MainLayout = props => {
  const { children } = props;

  const isSidebarOpen = () => {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }

  const componentWillReceiveProps = ({ breakpoint }) => {
    if (breakpoint !== props.breakpoint) {
      checkBreakpoint(breakpoint);
    }
  }

  useEffect(() => {
    checkBreakpoint(props.breakpoint);

    setTimeout(() => {
      if (!props.notificationSystem) return;

      props.notificationSystem.addNotification({
        title: <MdImportantDevices />,
        message: 'Welcome to Nextsix Admin Portal',
        level: 'info',
      });
    }, 1000);

    // setTimeout(() => {
    //   if (!this.notificationSystem) {
    //     return;
    //   }

    //   this.notificationSystem.addNotification({
    //     title: <MdLoyalty />,
    //     message:
    //       'Reduction is carefully designed template powered by React and Bootstrap4!',
    //     level: 'info',
    //   });
    // }, 2500);
  }, []);

  // close sidebar when
  const handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (isSidebarOpen() &&
      (props.breakpoint === 'xs' ||
        props.breakpoint === 'sm' ||
        props.breakpoint === 'md')
    ) {
      openSidebar('close');
    }
  };

  const checkBreakpoint = breakpoint => {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return openSidebar('open');
    }
  }

  const openSidebar = openOrClose => {
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }

  return (
    <main className="cr-app bg-light">
      <Sidebar loggedInUserModules={props.loggedInUserModules} />
      <Content fluid onClick={handleContentClick}>
        <Header />
        {children}
        <Footer />
      </Content>

      <NotificationSystem
        dismissible={false}
        ref={notificationSystem =>
          (notificationSystem = notificationSystem)
        }
        style={NOTIFICATION_SYSTEM_STYLE}
      />
    </main>
  );
}

export default MainLayout;
