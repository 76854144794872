export const NOTIFICATION_SYSTEM_STYLE = {
  NotificationItem: {
    DefaultStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      borderRadius: '4px',
      fontSize: '14px',
    },

    success: {
      borderTop: 0,
      backgroundColor: '#45b649',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    error: {
      borderTop: 0,
      backgroundColor: '#f85032',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    warning: {
      borderTop: 0,
      backgroundColor: '#ffd700',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    info: {
      borderTop: 0,
      background: 'linear-gradient(to right, #6a82fb, #fc5c7d)',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },
  },

  Title: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
      paddingRight: 5,
      color: '#fff',
      display: 'inline-flex',
      fontSize: 20,
      fontWeight: 'bold',
      // left: '15px',
      // position: 'absolute',
      // top: '50%',
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      display: 'block',
      color: '#fff',
      width: '100%',
    },
  },

  Dismiss: {
    DefaultStyle: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'inherit',
      fontSize: 20,
      color: '#f2f2f2',
      position: 'relative',
      margin: 0,
      padding: 0,
      background: 'none',
      borderRadius: 0,
      opacity: 1,
      width: 20,
      height: 20,
      textAlign: 'initial',
      float: 'none',
      top: 'unset',
      right: 'unset',
      lineHeight: 'inherit',
    },
  },

  Action: {
    DefaultStyle: {
      background: '#fff',
      borderRadius: '2px',
      padding: '6px 20px',
      fontWeight: 'bold',
      margin: '10px 0 0 0',
      border: 0,
    },

    success: {
      backgroundColor: '#45b649',
      color: '#fff',
    },

    error: {
      backgroundColor: '#f85032',
      color: '#fff',
    },

    warning: {
      backgroundColor: '#ffd700',
      color: '#fff',
    },

    info: {
      backgroundColor: '#00c9ff',
      color: '#fff',
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};
export const AGENT_LITE_PAGE_SORTING = [ //field name get from next6host api
  {
    value: {'properties.activeListing': -1},
    display: "Total Active Listing"
  },
  {
    value: {'properties.totalListing': -1},
    display: "Total Listing (Active + Inactive)"
  },
  {
    value: {'agent.latestUpdated': -1, 'properties.activeListing': -1},
    display: "Latest Updated Date"
  },
  {
    value: {'agent.latestPublished': -1, 'properties.activeListing': -1},
    display: "Latest Created Date"
  },
  {
    value: {'agent.firstLoginDate': -1},
    display: "Joined Date"
  },
  {
    value: {'name': 1},
    display: "Agent Full Name"
  },
  {
    value: {'nick': -1},
    display: "Agent Nickname"
  }
];
export const AGENT_PAGE_SORTING = [ //field name get from next6host api
  {
    value: {'activeProp': -1},
    display: "Total Active Listing"
  },
  {
    value: {'totalProp': -1},
    display: "Total Listing (Active + Inactive)"
  },
  {
    value: {'latestUpdated': -1, 'activeProp': -1}, //lookup from agents as agent
    display: "Latest Updated Date"
  },
  {
    value: {'latestPublished': -1, 'activeProp': -1}, //lookup from agents as agent
    display: "Latest Created Date"
  },
  {
    value: {'firstLoginDate': -1},
    display: "Joined Date"
  },
  {
    value: {'name': 1},
    display: "Agent Full Name"
  },
  {
    value: {'nickname': 1},
    display: "Agent Nickname"
  }
]
export const PAYMENT_STATUS_OPTIONS = [
  {
    value: "Success",
    label: "Successful"
  },
  {
    value: "Pending",
    label: "Pending"
  },
  {
    value: "Failed",
    label: "Failed"
  }
]