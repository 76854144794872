import moment from "moment";

const formatDateYMD = (date) => {
    let formatDate = moment(date).format("YYYY-MM-DD");
    return formatDate;
};

const formatDateMY = (date) => {
    console.log(date)
    const result = new Date(date).toLocaleString('en-us', { month: 'short', year: 'numeric' })
    return result
}

const formatDateTimeMY = (datetime) => {
    const datetimeValue = new Date(datetime);
    //return datetimeValue.toLocaleString();

    let hours = datetimeValue.getHours();
    let minutes = datetimeValue.getMinutes();
    const apm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${datetimeValue.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} ${hours}:${minutes}${apm}`;
};

const formatDateTime = (datetime) => {
    const datetimeValue = new Date(datetime);
    //return datetimeValue.toLocaleString();

    let hours = datetimeValue.getHours();
    let minutes = datetimeValue.getMinutes();
    const apm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${datetimeValue.toLocaleDateString()} ${hours}:${minutes}${apm}`;
};

const formatLongDate = (datetime) => {
    let today = new Date(datetime);
    let date = today.getDate();
    let month = today.toLocaleString('default', { month: 'short' });
    let year = String(today.getFullYear()).substring(2);
    let longDate = `${date}/${month}/${year}`;
    return longDate;
}

const parseToLocale = datetime => {
    const datetimeValue = new Date(datetime);
    // console.log(datetimeValue.getFullYear());
    // console.log(datetimeValue.getMonth());
    // console.log(datetimeValue.getDate());
    // console.log(datetimeValue.getHours() + ':00:00');
    // console.log('------- ');

    let month = datetimeValue.getMonth() + 1;
    if (month < 10) month = '0' + month;
    let date = datetimeValue.getDate();
    if (date < 10) date = '0' + date;
    let hour = datetimeValue.getHours()
    if (hour < 10) hour = '0' + hour;
    hour += ':00:00';
    return {
        datePart: `${datetimeValue.getFullYear()}-${month}-${date}`,
        timePart: hour
    };
}

const formatDate = (datetime) => {
    const datetimeValue = new Date(datetime);
    return `${datetimeValue.toLocaleDateString()}`;
};

const convertToISODate = (dateStr) => {
    // Parse the date string into day, month, and year (format: dd/MM/YYYY)
    const [day, month, year] = dateStr.split('/').map(Number);

    // Create a JavaScript Date object (month is 0-indexed in JavaScript)
    const jsDate = new Date(year, month - 1, day);

    // Calculate timezone offset in milliseconds
    const timezoneOffset = (new Date()).getTimezoneOffset() * 60000;

    // Convert JavaScript Date to ISO 8601 date string
    const isoDateString = new Date(jsDate.getTime() - timezoneOffset).toISOString().split('T')[0]; // Keep only the date part
    
    return isoDateString;
}

const convertISODateToDDMMYYYY = (isoDateString) => {
    const date = new Date(isoDateString); // Parse ISO date string

    const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (zero-indexed) and pad with leading zero if needed
    const year = date.getFullYear(); // Get full year
  
    // Return formatted date string in dd/MM/YYYY format
    return `${day}/${month}/${year}`;
}

const categoryOptions = (categories) => {
    const categoryList = categories.map(c => c.category);
    return categoryList;
};

const typeOptions = (categories, category) => {
    let typeList = [];
    const selectedCategories = categories.filter(c => c.category === category);
    if (selectedCategories.length > 0) {
        typeList = selectedCategories[0].types
    }
    return typeList;
};

const countryOptions = (countries) => {
    const countryList = countries.map(c => c.country);
    return countryList;
};

const stateOptions = (countries, country) => {
    let statesList = [];
    const selectedCountry = countries.filter(c => c.country === country);
    if (selectedCountry.length > 0) {
        statesList = selectedCountry[0].states.map(s => s.state);
    }
    return statesList;
};

const areaOptions = (countries, country, state) => {
    let final = {};
    let areaList = [];
    const selectedCountry = countries.filter(c => c.country === country);
    if (selectedCountry.length > 0) {
        const selectedState = selectedCountry[0].states.filter(s => s.state === state);
        if (selectedState.length > 0) {
            areaList = selectedState[0].areas;
        }
    }
    else {
        final.states = [];
    }
    final.areas = areaList;
    return final;
};

//Version 2 of state & area options from n6agentverse (new agent portal)
const stateOptionsV2 = (stateArea) => {
    const statesList = stateArea.map(({ state }) => state);
    return statesList;
};

const areaOptionsV2 = (countryStates, state) => {
    let areaList = [];
    const selectedState = countryStates.filter(s => s.state === state);
    if (selectedState.length > 0) {
        areaList = selectedState[0].areas;
    }
    return areaList;
};

const formatCurrency = (n) => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const fullAddress = (address) => {
    let fullAddress = '';
    if (address) {
        if (address.line1) fullAddress += (address.line1 + ', ');
        if (address.line2) fullAddress += (address.line2 + ', ');
        if (address.city) fullAddress += (address.city + ', ');
        if (address.postcode) fullAddress += (address.postcode + ', ');
        if (address.state) fullAddress += (address.state + ', ');
        if (address.country) fullAddress += address.country;
    }
    return (fullAddress.length > 15 ? fullAddress : undefined);
};

const displayLoc = loc => {
    if (loc && loc.coordinates) return `[${loc.coordinates[1]},${loc.coordinates[0]}]`;
    return `[0,0]`;
};

const bytes2String = fileSizeList => {
    let newFileSizes = [];
    fileSizeList.map((fileSize) => {
        if (fileSize < 1000) {
            fileSize = fileSize.toString() + " bytes";
        } else if (fileSize < 1000000) {
            fileSize =
                (fileSize / Math.pow(1024, 1))
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,2})?/)[0] + " KB";
        } else {
            fileSize =
                (fileSize / Math.pow(1024, 2))
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,2})?/)[0] + " MB";
        }
        newFileSizes.push(fileSize);
    });
    console.log(newFileSizes)
    return newFileSizes
}

const log = (val) => {
    if (process.env.REACT_APP_DEV_MODE === `true`)
        console.log(val);
};

const thousandSeparator = (val) => {
    return val.replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const downloadFile = (data, filename) => {
    // Create a download link using the response data
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object and remove the download link
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
}

export default {
    formatDateYMD: formatDateYMD,
    formatDateMY: formatDateMY,
    formatDate: formatDate,
    formatDateTimeMY,
    formatDateTime: formatDateTime,
    formatLongDate: formatLongDate,
    parseToLocale: parseToLocale,
    convertToISODate: convertToISODate,
    convertISODateToDDMMYYYY: convertISODateToDDMMYYYY,
    categoryOptions: categoryOptions,
    typeOptions: typeOptions,
    countryOptions: countryOptions,
    stateOptions: stateOptions,
    stateOptionsV2: stateOptionsV2,
    areaOptions: areaOptions,
    areaOptionsV2: areaOptionsV2,
    formatCurrency: formatCurrency,
    fullAddress: fullAddress,
    displayLoc: displayLoc,
    bytes2String: bytes2String,
    log: log,
    thousandSeparator: thousandSeparator,
    downloadFile: downloadFile,
}