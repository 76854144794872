import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import credentialComponent from '../biz/credential';
import { Button, FormGroup, Input, Label, Row, Col, Card, Modal, ModalBody, ModalFooter } from 'reactstrap';

const AuthResetPage = (props) => {

  //const [key, setKey] = useState(props.match.params.key || '');
  const key = props.match.params.key || null;
  const [isValid, setIsValid] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [keyProfile, setKeyProfile] = useState(null);
  const [newCredential, setNewCredential] = useState({});
  const [error, setError] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    console.log(`key? ${props.match.params.key}`);
    if (key) {
      validateKey();
    }
  }, []);

  const validateKey = async () => {
    const keyProfile = await credentialComponent.hashKey(key);
    console.log(keyProfile);
    if (keyProfile) {
      setIsValid(true);
      setKeyProfile(keyProfile);
    }
  };

  const assignValue = (name, value) => {
    setNewCredential(newCredential => ({
      ...newCredential,
      [name]: value
    }));
    setError('');
  };

  const handleChange = event => {
    assignValue([event.target.name], event.target.value);
  };

  const save = async () => {
    setError('');
    const res = await credentialComponent.validate(newCredential);
    if (res.ok) {
      console.log(`saved validation ok`);
      setCanSave(true);
    }
    else {
      setError(res.err);
    }
  };

  useEffect(() => {
    if (canSave) {
      saveCredential();
      toggle();
    }
  }, [canSave]);

  const saveCredential = async () => {
    await credentialComponent.save(keyProfile._id, newCredential.password);
    console.log('saved done');
  }

  const toggle = () => {
    setModal(!modal);
    // this.setState(prevState => ({
    //   modal: !prevState.modal
    // }))
  }

  const handleGoToLogin = (event) => {
    event.preventDefault();
    setModal(!modal);
    window.location = '/';
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Col md={6} lg={4}>
        <Card body>
          {isValid &&
            <>
              <FormGroup>
                <Label for="New password">New password</Label>
                <Input type="password" className="form-control" placeholder="New password" name="password" value={newCredential.password || ''} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="confirm new password">Confirm new password</Label>
                <Input type="password" className="form-control" placeholder="Confirm new password" name="confirmPassword" value={newCredential.confirmPassword || ''} onChange={handleChange} />
              </FormGroup>
              {
                error &&
                <div className="form-group">
                  <p className="text-danger">{error}</p>
                </div>
              }
              <hr />
              <Button size="lg" className="bg-gradient-theme-left border-0" block
                onClick={() => save()}> Submit
            </Button>

              <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                <ModalBody>
                  Password reset successfully. Sign in to continue
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleGoToLogin}>OK</Button>
                </ModalFooter>
              </Modal>
            </>
          }

          {!isValid &&
            <>
              <h2>
                <img alt="nextsix" src="https://iprofstore.blob.core.windows.net/general/nextsix.png" style={{ width: "120px", height: "120px" }} />
              </h2>
              <h2>Sorry, the link has expired.</h2>
            </>
          }
        </Card>
      </Col>
    </Row>
  );
}

AuthResetPage.propTypes = {
  key: PropTypes.string,
  keyProfile: PropTypes.object,
  submitCallback: PropTypes.func,
};

export default AuthResetPage;