import React, { useState } from 'react';
//import { MdSearch } from 'react-icons/md';
import {
  Form
} from 'reactstrap';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import SetupData from "../data/SetupData";
import { useTranslation } from 'react-i18next';

const SearchInput = () => {
  const { t } = useTranslation();

  const items = SetupData.sidebarInput;
  items.sort((a, b) => {
    if (a.description > b.description) return 1;
    if (a.description < b.description) return -1;
    else return 0;
})

// console.log(items);

  const [value, setValue] = useState('');
  //const [ready, setReady] = useState(true);

  const handleInput = e => {
    setValue(e.target.value);
  };

  const handleSelect = page => {
    setValue(page);
    goTo(page);
  };

  const handleSearchEnter = e => {
    if (e.key.toLowerCase() === 'enter' && e.target.value)
      handleSelect(e.target.value);
  };

  const goTo = (page) => {
    items.forEach(item => {
      if (item.description === page) {
        const redirect = `/${item.id}`;
        console.log(`goto ${redirect}`);
        window.location = redirect;
        return;
      }
    });
  };

  const renderSuggestions = () => {
    const suggestions = items.map(({ id, description }) => (
      value && description.toLowerCase().indexOf(value.toLowerCase()) >= 0 &&
      <ComboboxOption key={id} value={description} />
    ));

    // let suggestions;
    // if (value) {
    //   suggestions = items.map(({ id, description }) => (
    //     value && description.toLowerCase().indexOf(value.toLowerCase()) >= 0 &&
    //     <ComboboxOption key={id} value={description} />
    //   ));
    // }
    // else {
    //   suggestions = items.map(({ id, description }) => (
    //     <ComboboxOption key={id} value={description} />
    //   ));
    // }

    return (
      <>
        {suggestions}
      </>
    );
  };

  return (
    <Form inline className="cr-search-form" onSubmit={e => e.preventDefault()}>
      {/* <MdSearch
        size="30"
        className="cr-search-form__icon-search text-secondary"
      /> 
       <Input
        type="search"
        className="cr-search-form__input"
        placeholder="Search..."
      /> */}
      <Combobox onSelect={handleSelect} aria-labelledby="pages">
        <ComboboxInput placeholder={`${t('search')} ..`}
          style={{ height: 40, width: 300, maxWidth: "90%" }}
          value={value}
          onChange={handleInput}
          onKeyUp={handleSearchEnter}
        //disabled={!ready}
        />
        <ComboboxPopover>
          <ComboboxList>{renderSuggestions()}</ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </Form>
  );
};

export default SearchInput;
