export const login = user => {
    return {
        type: 'LOGGED_IN',
        user: user
    }
};

export const logoff = () => {
    return {
        type: 'LOGGED_OUT'
    }
};
