import apiCredential from '../api/CredentialAPI';

const hashKey = async (hashKey) => {
    const result = await apiCredential.hashKey(hashKey);
    return result;
};

const invalid = err => {
    return {
        ok: false,
        err: err
    }
};

const validate = (credential) => {
    if (!credential.password) return invalid('New password is required');
    if (!credential.confirmPassword) return invalid('Confirm new password is required');
    if (credential.password !== credential.confirmPassword) return invalid('New password and Confirm password do not match');
    
    return {
        ok: true
    };
};

const save = async(id, password)=>{
    await apiCredential.save(id, password);
}

export default {
    hashKey: hashKey,
    validate: validate,
    save: save,
}