import user from '../reducers/user';
// import selectedAgent from '../reducers/selectedAgent';
//import selectedProperty from '../reducers/selectedProperty';
import rootFilter from '../reducers/rootFilter';
import lang from '../reducers/lang';
import groupListing from '../reducers/groupListing';
import packages from '../reducers/packages';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    user,
    // selectedAgent, 
    //selectedProperty, 
    rootFilter,
    lang,
    groupListing,
    packages
});

export default allReducers;
