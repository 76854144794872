const groupListing = (state = null, action) => {
    switch (action.type) {
        case 'STORE_GROUP':
            return action.groupListing ? action.groupListing : null;
        case 'CLEAR_GROUP':
            return null;
        default: return state;
    }
};

export default groupListing;