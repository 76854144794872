import apiAuditTrail from '../api/AuditTrailAPI';

const adminLatest = async (search, paging) => {
    return await apiAuditTrail.adminLatest(search, paging);
}

const agentLatest = async (search, paging) => {
    return await apiAuditTrail.agentLatest(search, paging);
}

const adminLog = async (adminId, action) => {
    return await apiAuditTrail.adminLog(adminId, action);
}

export default {
    adminLatest,
    agentLatest,
    adminLog,
}
