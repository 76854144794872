import apibase from '../BaseAPI';

const id = async (id) => {
    const url = `/userprofiles/${id}`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const newId = async () => {
    const url = `/userprofiles/newId`;
    try {
        const result = await apibase.post(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const save = async (userProfile) => {
    const url = `/userprofiles/save`;
    try {
        const result = await apibase.post(url, userProfile);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const search = async (val, activeUserOnly = false, userLevel, currentLength) => {
    const params = {
        level: userLevel,
        search: val,
        activeUserOnly,
        currentLength: currentLength
    };
    const url = `/userprofiles/search`;
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const countSearch = async (val, activeUserOnly = false, userLevel) => {
    const params = {
        level: userLevel,
        search: val,
        activeUserOnly
    };

    const url = `/userprofiles/countSearch`;
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const login = async (username, password) => {
    const params = {
        username: username,
        password: password
    };
    const url = `/userprofiles/login`;
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const resetPassword = async (id) => {
    const params = {
        id: id
    };
    const url = `/userprofiles/resetPassword`;
    try {
        await apibase.post(url, params);
    } catch (err) {
        throw err;
    }
};

const forgotPassword = async (email) => {
    const params = {
        email: email
    };
    const url = `/userprofiles/forgotPassword`;
    try {
        await apibase.post(url, params);
    } catch (err) {
        throw err;
    }
};

const usernameIsUnique = async (id, username) => {
    const params = {
        id: id,
        username: username
    };
    const url = `/userprofiles/username/unique`;
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const emailIsUnique = async (id, email) => {
    const params = {
        id: id,
        email: email
    };
    const url = `/userprofiles/email/unique`;
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

export default {
    id: id,
    newId: newId,
    save: save,
    search: search,
    countSearch: countSearch,
    login: login,
    resetPassword: resetPassword,
    forgotPassword: forgotPassword,
    usernameIsUnique: usernameIsUnique,
    emailIsUnique: emailIsUnique,
};