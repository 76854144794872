import apibase from './BaseAPI';

const hashKey = async (hashKey) => {
    const url = `/credential/${hashKey}`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const save = async (id, password) => {
    const params ={
        _id: id,
        newPassword: password
    };
    const url = `/credential/save`;
    try {
        await apibase.post(url, params);       
    } catch (err) {
        throw err;
    }
};

export default {
    hashKey: hashKey,
    save: save,
}