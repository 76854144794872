import React from 'react';

const UnauthorizedPage = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h2>
                        <img alt="nextsix" src="https://iprofstore.blob.core.windows.net/general/nextsix.png" style={{ width: "120px", height: "120px" }} />
                    </h2>
                    <h2>401. Unauthorized</h2>
                </div>
            </div>
        </div>
    );
}

export default UnauthorizedPage;