import SourceLink from 'components/SourceLink';
import React, { useState, Fragment, useEffect } from 'react';
import {
  MdArrowDropDownCircle,
  MdKeyboardArrowDown,
  MdWeb,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { Translation } from 'react-i18next';
import SetupData from 'data/SetupData';

const sidebarBackground = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

const Sidebar = props => {
  const loggedInUserModules = props.loggedInUserModules;
  const paymentGatewayCompany = SetupData.paymentGateway.key;
  const hasPGReadAccess = loggedInUserModules.some(modules => {
    return paymentGatewayCompany.some(key => modules.name.includes(key) && modules.read);
  });
  const hasPGWriteAccess = loggedInUserModules.some(modules => {
    return paymentGatewayCompany.some(key => modules.name.includes(key) && modules.write);
  });

  const masterGroups = [{
    group: 'setup',
    stateName: 'isOpenMaster',
    GroupIcon: MdArrowDropDownCircle,
    navItems: [
      { to: '/advertisements', name: 'advertisement', exact: false, Icon: MdWeb, id: 'advertisements' },
      { to: '/announcement', name: 'announcement', exact: false, Icon: MdWeb, id: 'announcement' },
      { to: '/areamaster', name: 'area_master', exact: false, Icon: MdWeb, id: 'area master' },
      { to: '/feature/property', name: 'feature_properties', exact: false, Icon: MdWeb, id: 'feature properties' },
      { to: '/luckydrawprize', name: 'lucky_draw_prize_list', exact: false, Icon: MdWeb, id: 'luckyDraw' },
      { to: '/master/property', name: 'property_master', exact: false, Icon: MdWeb, id: 'property master' },
      // { to: '/branchaddress', name: 'branch address', exact: false, Icon: MdWeb, id: 'agents' },
      { to: '/aisearch', name: 'ai search', exact: false, Icon: MdWeb, id: 'aisearch' },
    ]
  }
  ];

  const navItemsErp213 = [
    { to: '/erp213/log', name: 'Erp213 Log', exact: true, Icon: MdWeb, id: 'erp213' },
  ];

  const navItemsBottom = [
    { to: '/about', name: 'about', exact: false, Icon: MdWeb, id: 'about' },
  ];

  const navItemsTop = [{
    to: '/dashboard', name: 'dashboard', exact: true, Icon: MdWeb, id: 'dashboard'
  }];

  const navItemsSecond = [
    { to: '/audit', name: 'audit', exact: true, Icon: MdWeb, id: 'audit' }
  ];

  const biGroups = [{
    group: 'Business Intelligent',
    stateName: 'isOpenBi',
    GroupIcon: MdArrowDropDownCircle,
    navItems: [
      { to: '/bi2', name: 'NextSix\'s Activities', exact: true, Icon: MdWeb, id: 'bipages' },
      { to: '/bi5', name: 'Sales Analytic', exact: true, Icon: MdWeb, id: 'bipages' },
      { to: '/bi', name: 'Sales Trending', exact: true, Icon: MdWeb, id: 'bipages' },
    ]
  }];

  const adminstrationGroups = [
    {
      group: 'administration',
      stateName: 'isOpenAdmins',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/audittrails', name: 'audit trails', exact: false, Icon: MdWeb, id: 'audittrails' },
        { to: '/userprofiles', name: 'user profiles', exact: false, Icon: MdWeb, id: 'users' },
        { to: '/roles', name: 'user roles', exact: false, Icon: MdWeb, id: 'roles' },
      ]
    }
  ];

  const agentGroups = [
    {
      group: 'agency',
      stateName: 'isOpenAgents',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/agents', name: 'agents', exact: true, Icon: MdWeb, id: 'agents' },
        { to: '/agentapplication', name: 'agent application', exact: true, Icon: MdWeb, id: 'agent application' },
        { to: '/agentonlinereport', name: 'agent online report', exact: false, Icon: MdWeb, id: 'agent online report' },
        { to: '/agentpayment', name: 'agent payment', exact: true, Icon: MdWeb, id: 'agent payment' },
        { to: '/agentplotting', name: 'agent plotting', exact: false, Icon: MdWeb, id: 'agent plotting' },
        { to: '/appointment', name: 'appointment', exact: false, Icon: MdWeb, id: 'appointment' },
        { to: '/areaspecialistsoverview', name: 'area specialists overview', exact: false, Icon: MdWeb, id: 'area specialists overview' },
        { to: '/challenges', name: 'challenges', exact: false, Icon: MdWeb, id: 'challenge' },
        { to: '/company', name: 'company', exact: false, Icon: MdWeb, id: 'company' },
        { to: '/cobroke', name: 'co-broke', exact: false, Icon: MdWeb, id: 'cobroke' },
        { to: '/datasummaryreport', name: 'data summary report', exact: true, Icon: MdWeb, id: 'data summary report' },
        { to: '/enquiryReminder', name: 'enquiry reminder', exact: false, Icon: MdWeb, id: 'enquiry reminder' },
        { to: '/flagmanagement', name: 'flag management', exact: false, Icon: MdWeb, id: 'flag management' },
        { to: '/listingmigration', name: 'listing migration', exact: false, Icon: MdWeb, id: 'listing migration' },
        { to: '/listingmigrationLog', name: 'listing migration log', exact: false, Icon: MdWeb, id: 'listing migration log' },
        { to: '/agents/master/property', name: 'new property master request', exact: false, Icon: MdWeb, id: 'property master' },
        { to: '/properties', name: 'properties', exact: false, Icon: MdWeb, id: 'properties' },
        { to: '/agents/role', name: 'REN ID request', exact: false, Icon: MdWeb, id: 'agents' },
        { to: '/showcase', name: 'showcase', exact: false, Icon: MdWeb, id: 'showcase' },
        { to: '/specialists', name: 'Specialists', exact: false, Icon: MdWeb, id: 'newspecialists' },
        { to: '/agents/tnc', name: 'terms of use update', exact: false, Icon: MdWeb, id: 'agents' },
        { to: '/videos', name: 'videos', exact: false, Icon: MdWeb, id: 'properties' },
        // { to: '/agents/specialists', name: 'specialists', exact: false, Icon: MdWeb, id: 'specialists' },
        // { to: '/areaspecialists', name: 'area specialists', exact: false, Icon: MdWeb, id: 'area specialists' },
        // { to: '/condospecialists', name: 'condo specialists', exact: false, Icon: MdWeb, id: 'condo specialists' },
      ]
    }
  ];

  const redeemGroups = [
    {
      group: 'redemption',
      stateName: 'isOpenRedeem',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/rewarditems', name: 'reward items', exact: false, Icon: MdWeb, id: 'redeem items' },
        { to: '/redemption', name: 'transactions', exact: false, Icon: MdWeb, id: 'redeem transactions' }
      ]
    }
  ];

  const developerGroups = [
    {
      group: 'developer',
      stateName: 'isOpenDeveloper',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/developers', name: 'developers', exact: false, Icon: MdWeb, id: 'developers' },
        { to: '/projects', name: 'projects', exact: false, Icon: MdWeb, id: 'projects' },
      ]
    }
  ];

  const bidNowAdminGroups = [
    {
      group: 'BidNow admin',
      stateName: 'isOpenBidNow',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/bidnow/userprofiles', name: 'user profiles', exact: false, Icon: MdWeb, id: 'BidNow users' },
        { to: '/bidnow/roles', name: 'user roles', exact: false, Icon: MdWeb, id: 'BidNow user roles' },
      ]
    }
  ];

  const AuctionGroups = [
    {
      group: 'auction',
      stateName: 'isOpenAuction',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/bidnow/auction/bidders', name: 'bidders', exact: false, Icon: MdWeb, id: 'bidders' },
        { to: '/bidnow', name: 'BidNow', exact: true, Icon: MdWeb, id: 'auction' },
        { to: '/bidnow/auction/process', name: 'process', exact: false, Icon: MdWeb, id: 'auction' },
        { to: '/bidnow/auction/properties', name: 'properties', exact: false, Icon: MdWeb, id: 'auction' },
      ]
    }
  ];

  const AppUserGroups = [
    {
      group: 'App User',
      stateName: 'isOpenAppUser',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/appusers', name: 'app users', exact: false, Icon: MdWeb, id: 'app users' },
        { to: '/luckydraw', name: 'lucky draw', exact: false, Icon: MdWeb, id: 'luckyDraw' },
      ]
    }
  ];

  const AgentAcademyGroups = [
    {
      group: 'Academy',
      stateName: 'isOpenAgentAcademy',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/assessment', name: 'assessment', exact: false, Icon: MdWeb, id: 'assessment' },
        { to: '/guidance', name: 'guidance', exact: false, Icon: MdWeb, id: 'workshop' },
      ]
    }
  ];

  const ProformaGroups = [
    {
      group: 'Proforma',
      stateName: 'isOpenProforma',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/invoice', name: 'invoice', exact: false, Icon: MdWeb, id: 'invoice' },
        { to: '/paymentvoucher', name: 'payment voucher', exact: false, Icon: MdWeb, id: 'payment voucher' },
        { to: '/receipt', name: 'receipt', exact: false, Icon: MdWeb, id: 'receipt' },
        { to: '/salequotation', name: 'sale quotation', exact: false, Icon: MdWeb, id: 'sale quotation' },
      ]
    }
  ];

  // let oldPortal = [
  //   { to: '/merchants', name: 'merchant', exact: false, Icon: MdWeb, id: 'service merchants' },
  //   { to: '/enquiry', name: 'enquiries', exact: false, Icon: MdWeb, id: 'service merchants' },
  //   { to: '/referrals', name: 'referrals', exact: false, Icon: MdWeb, id: 'service merchants' },
  // ]

  const HomeProGroups = [
    {
      group: 'HomePro',
      stateName: 'isOpenHomePro',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/merchants/application', name: 'merchant application', exact: false, Icon: MdWeb, id: 'service merchants' },
        { to: '/enquiry', name: 'merchant enquiries', exact: false, Icon: MdWeb, id: 'service merchants' },
        { to: '/merchants/profile', name: 'merchant profile', exact: false, Icon: MdWeb, id: 'service merchants' },
      ]
    }
  ];

  const AuditGroups = [
    {
      group: 'audit',
      stateName: 'isOpenAudit',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/searchlog', name: 'search log', exact: true, Icon: MdWeb, id: 'audit' },
        { to: '/propertysummaryreport', name: 'property summary report', exact: true, Icon: MdWeb, id: 'audit' },
      ]
    }
  ];

  const BDGroups = [
    {
      group: 'Business Developement',
      stateName: 'isOpenBd',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/bdProfile', name: 'BD profile', exact: true, Icon: MdWeb, id: 'bd management' },
        { to: '/bdSetting', name: 'BD setting', exact: true, Icon: MdWeb, id: 'bd setting' },
        { to: '/bdSummary', name: 'BD summary', exact: true, Icon: MdWeb, id: 'bd management' },
      ]
    }
  ];

  const GroupListing = [
    {
      group: 'Group Listing',
      stateName: 'isOpenGroupListing',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/grouplistingapplication', name: 'group listing application', exact: true, Icon: MdWeb, id: 'grouplisting' },
        { to: '/grouplisting', name: 'group listing overview', exact: true, Icon: MdWeb, id: 'grouplisting' },
      ]
    }
  ];

  const MeetingRoom = [
    {
      group: 'Meeting Room',
      stateName: 'isOpenMeetingRoom',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        { to: '/meetingroom', name: 'meeting room', exact: true, Icon: MdWeb, id: 'meeting room' },
        { to: '/meetingroomapplication', name: 'meeting room application', exact: true, Icon: MdWeb, id: 'meeting room' }
      ]
    }
  ];

  const PaymentGateway = [
    {
      group: 'Payment Gateway',
      stateName: 'isOpenPaymentGateway',
      GroupIcon: MdArrowDropDownCircle,
      navItems: [
        hasPGWriteAccess ? { to: '/paymentgateway/platform', name: 'payment gateway platform', exact: true, Icon: MdWeb, id: 'payment gateway' } : [],
        hasPGReadAccess ? { to: '/paymentgateway/transactions', name: 'payment gateway transaction', exact: true, Icon: MdWeb, id: 'payment gateway' } : [],
      ]
    }
  ];

  const checkModuleAccess = moduleId => {
    if ('dashboard;bi;bi2;bi5;about;'.indexOf(moduleId) >= 0) return true;
    else {
      const result = loggedInUserModules.find(modules => modules.name === moduleId && modules.read);
      return result ? true : false;
    }
  };

  const checkGroupModuleAccess = groupModule => {
    let finalList = [];
    groupModule.map(group => {
      group.navItems.map(item => {
        if (checkModuleAccess(item.id))
          finalList.push(item);
      })
    });
    return finalList;
  };

  const removeLocalStorage = (name) => {
    if (name === "properties") {
      localStorage.removeItem('selectedPropertyAgent');
    }
  };

  const authenticateAdminGroupNavItems = () => {
    return checkGroupModuleAccess(adminstrationGroups);
  };

  const authenticateBusinessIntelligentNavItems = () => {
    return checkGroupModuleAccess(biGroups);
  }

  const authenticateAgentGroupNavItems = () => {
    return checkGroupModuleAccess(agentGroups);
  };

  const authenticateRedeemGroupNavItems = () => {
    return checkGroupModuleAccess(redeemGroups);
  };

  const authenticateMasterGroupNavItems = () => {
    return checkGroupModuleAccess(masterGroups);
  };

  const authenticateDeveloperGroupNavItems = () => {
    return checkGroupModuleAccess(developerGroups);
  };

  const authenticateBidNowAdminGroupNavItems = () => {
    return checkGroupModuleAccess(bidNowAdminGroups);
  };

  const authenticateAuctionGroupNavItems = () => {
    return checkGroupModuleAccess(AuctionGroups);
  };

  const authenticateAppUserGroupNavItems = () => {
    return checkGroupModuleAccess(AppUserGroups);
  };

  const authenticateAgentAcademyGroupNavItems = () => {
    return checkGroupModuleAccess(AgentAcademyGroups);
  };

  const authenticateProformaGroupNavItems = () => {
    return checkGroupModuleAccess(ProformaGroups);
  };

  const authenticateHomeProGroupNavItems = () => {
    return checkGroupModuleAccess(HomeProGroups);
  };

  const authenticateAuditGroupNavItems = () => {
    return checkGroupModuleAccess(AuditGroups);
  };

  const authenticateBdGroupNavItems = () => {
    return checkGroupModuleAccess(BDGroups);
  };

  const authenticateGroupListingNavItems = () => {
    return checkGroupModuleAccess(GroupListing);
  };

  const authenticateMeetingRoomNavItems = () => {
    return checkGroupModuleAccess(MeetingRoom);
  };

  const authenticatePaymentGatewayNavItems = () => {
    return (hasPGReadAccess || hasPGWriteAccess) ? checkGroupModuleAccess(PaymentGateway) : false;
  };

  const [isOpen, setIsOpen] = useState({
    isOpenBi: false,
    isOpenContents: false,
    isOpenPages: false,
    isOpenAdmins: false,
    isOpenAgents: false,
    isOpenShops: false,
    isOpenRedeem: false,
    isOpenMaster: false,
    isOpenDeveloper: false,
    isOpenBidNow: false,
    isOpenAuction: false,
    isOpenAppUser: false,
    authenticateAdminGroup: authenticateAdminGroupNavItems(),
    authenticateBusinessIntelligent: authenticateBusinessIntelligentNavItems(),
    authenticateMasterGroup: authenticateMasterGroupNavItems(),
    authenticateAgentGroup: authenticateAgentGroupNavItems(),
    authenticateRedeemGroup: authenticateRedeemGroupNavItems(),
    authenticateDeveloperGroup: authenticateDeveloperGroupNavItems(),
    authenticateBidNowAdminGroup: authenticateBidNowAdminGroupNavItems(),
    authenticateAuctionGroup: authenticateAuctionGroupNavItems(),
    authenticateAppUserGroup: authenticateAppUserGroupNavItems(),
    authenticateAgentAcademyGroup: authenticateAgentAcademyGroupNavItems(),
    authenticateProformaGroup: authenticateProformaGroupNavItems(),
    authenticateHomeProGroup: authenticateHomeProGroupNavItems(),
    authenticateAuditGroup: authenticateAuditGroupNavItems(),
    authenticateBdGroup: authenticateBdGroupNavItems(),
    authenticateGroupListing: authenticateGroupListingNavItems(),
    authenticateMeetingRoom: authenticateMeetingRoomNavItems(),
    authenticatePaymentGateway: authenticatePaymentGatewayNavItems(),
  });

  //edit sidebar items arrangement here
  const navItemGroups = [
    {
      parent: adminstrationGroups,
      navItems: isOpen.authenticateAdminGroup
    },
    {
      parent: agentGroups,
      navItems: isOpen.authenticateAgentGroup,
      removeLocalStorage
    },
    {
      parent: AppUserGroups,
      navItems: isOpen.authenticateAppUserGroup
    },
    {
      parent: BDGroups,
      navItems: isOpen.authenticateBdGroup
    },
    {
      parent: biGroups,
      navItems: isOpen.authenticateBusinessIntelligent
    },
    {
      parent: developerGroups,
      navItems: isOpen.authenticateDeveloperGroup
    },
    {
      parent: GroupListing,
      navItems: isOpen.authenticateGroupListing
    },
    {
      parent: HomeProGroups,
      navItems: isOpen.authenticateHomeProGroup
    },
    {
      parent: MeetingRoom,
      navItems: isOpen.authenticateMeetingRoom
    },
    {
      parent: PaymentGateway,
      navItems: isOpen.authenticatePaymentGateway
    },
    {
      parent: ProformaGroups,
      navItems: isOpen.authenticateProformaGroup
    },
    {
      parent: masterGroups,
      navItems: isOpen.authenticateMasterGroup,
      hasTranslation: true,
    },
    {
      parent: AgentAcademyGroups,
      navItems: isOpen.authenticateAgentAcademyGroup
    },
    {
      parent: AuctionGroups,
      navItems: isOpen.authenticateAuctionGroup
    },
    {
      parent: bidNowAdminGroups,
      navItems: isOpen.authenticateBidNowAdminGroup
    },
    {
      parent: redeemGroups,
      navItems: isOpen.authenticateRedeemGroup
    },
  ]

  const handleClick = name => () => {
    setIsOpen(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  return (<>
    <aside className={bem.b()}>
      <div className={bem.e('background')} style={sidebarBackground} />
      <div className={bem.e('content')}>
        <Navbar>
          <SourceLink className="navbar-brand d-flex">
            <img
              src={"https://iprofstore.blob.core.windows.net/general/newNextsix.png"}
              width="40"
              height="30"
              className="pr-2"
              alt=""
            />
            <span className="text-white">
              NextSix.Admin
            </span>
          </SourceLink>
        </Navbar>
        <Nav vertical>
          {navItemsTop.map(({ to, name, exact, Icon, id }, index) => (
            checkModuleAccess(id) &&
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className=""
                tag={NavLink}
                to={to}
                activeClassName="active"
                exact={exact}
              >
                <Icon className={bem.e('nav-item-icon')} />
                {/* <span className="">{name}</span> */}
                <span>
                  <Translation>
                    {
                      (t) => <>{t(name)}</>
                    }
                  </Translation>
                </span>
              </BSNavLink>
            </NavItem>
          ))}

          {navItemsSecond.map(({ to, name, exact, Icon, id }, index) => (
            checkModuleAccess(id) &&
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className=""
                tag={NavLink}
                to={to}
                activeClassName="active"
                exact={exact}
              >
                <Icon className={bem.e('nav-item-icon')} />
                <span className="">{name}</span>
                {/* <span>
                  <Translation>
                    {
                      (t) => <>{t(name)}</>
                    }
                  </Translation>
                </span> */}
              </BSNavLink>
            </NavItem>
          ))}

          {navItemGroups.map((grp, idx) => (
            <NavItemGroup
              key={idx}
              parentGroup={grp.parent}
              navItemArr={grp.navItems}
              handleClick={handleClick}
              isOpen={isOpen}
              hasTranslation={grp.hasTranslation}
              removeLocalStorage={grp.removeLocalStorage}
            />
          ))}

          {/* {isOpen.authenticateBusinessIntelligent.length > 0 &&
            biGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateBusinessIntelligent.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  )
                  )}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* // */}

          {/* {isOpen.authenticateAdminGroup.length > 0 &&
            adminstrationGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateAdminGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* HIDDEN BEFORE DOING NXT1477 */}
          {/* {isOpen.authenticateAuditGroup.length > 0 &&
            AuditGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateAuditGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateBdGroup.length > 0 &&
            BDGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateBdGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateGroupListing.length > 0 &&
            GroupListing.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateGroupListing.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateMeetingRoom.length > 0 &&
            MeetingRoom.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateMeetingRoom.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateMasterGroup.length > 0 &&
            masterGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span>
                        <Translation>
                          {
                            (t) => <>{t(group)}</>
                          }
                        </Translation>
                      </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateMasterGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span>
                          <Translation>
                            {
                              (t) => <>{t(name)}</>
                            }
                          </Translation>
                        </span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateAgentGroup.length > 0 &&
            agentGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateAgentGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                        onClick={() => removeLocalStorage(name)}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="" >{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateRedeemGroup.length > 0 &&
            redeemGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateRedeemGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateDeveloperGroup.length > 0 &&
            developerGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateDeveloperGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateBidNowAdminGroup.length > 0 &&
            bidNowAdminGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateBidNowAdminGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateAuctionGroup.length > 0 &&
            AuctionGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateAuctionGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}
          {/* {isOpen.authenticateAppUserGroup.length > 0 &&
            AppUserGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateAppUserGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateAgentAcademyGroup.length > 0 &&
            AgentAcademyGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateAgentAcademyGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateProformaGroup.length > 0 &&
            ProformaGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateProformaGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {/* {isOpen.authenticateHomeProGroup.length > 0 &&
            HomeProGroups.map(({ group, stateName, GroupIcon }) => (
              <Fragment key={group}>
                <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <GroupIcon className={bem.e('nav-item-icon')} />
                      <span className="">{group}</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: isOpen[stateName]
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
                  {isOpen.authenticateHomeProGroup.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <span className={bem.e('nav-item-icon')} >{''}</span>
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          } */}

          {navItemsErp213.map(({ to, name, exact, Icon, id }, index) => (
            checkModuleAccess(id) &&
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className=""
                tag={NavLink}
                to={to}
                activeClassName="active"
                exact={exact}
              >
                <Icon className={bem.e('nav-item-icon')} />
                {/* <span className=""><Trans>{name}</Trans></span> */}
                <span>
                  <Translation>
                    {
                      (t) => <>{t(name)}</>
                    }
                  </Translation>
                </span>
              </BSNavLink>
            </NavItem>
          ))}

          {navItemsBottom.map(({ to, name, exact, Icon, id }, index) => (
            checkModuleAccess(id) &&
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className=""
                tag={NavLink}
                to={to}
                activeClassName="active"
                exact={exact}
              >
                <Icon className={bem.e('nav-item-icon')} />
                {/* <span className=""><Trans>{name}</Trans></span> */}
                <span>
                  <Translation>
                    {
                      (t) => <>{t(name)}</>
                    }
                  </Translation>
                </span>
              </BSNavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
    </aside>
  </>);

}

export default Sidebar;

const NavItemGroup = ({ parentGroup, navItemArr, handleClick, isOpen, removeLocalStorage, hasTranslation }) => {
  return (
    <>
      {navItemArr.length > 0 &&
        parentGroup.map(({ group, stateName, GroupIcon }) => (
          <Fragment key={group}>
            <NavItem className={bem.e('nav-item')} onClick={handleClick(stateName)}>
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <GroupIcon className={bem.e('nav-item-icon')} />
                  <span className="">
                    {hasTranslation ?
                      <Translation>
                        {(t) => <>{t(group)}</>}
                      </Translation>
                      : group
                    }
                  </span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: isOpen[stateName]
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={isOpen[stateName]} className={bem.e('collapse')}>
              {navItemArr.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={`${group}-${index}`} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className=""
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                    onClick={() => removeLocalStorage && removeLocalStorage(name)}
                  >
                    <span className={bem.e('nav-item-icon')} >{''}</span>
                    <span className="">
                      {hasTranslation ?
                        <Translation>
                          {(t) => <>{t(name)}</>}
                        </Translation>
                        : name
                      }
                    </span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </Fragment>
        ))
      }
    </>
  )
}