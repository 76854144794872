import React from 'react';
import { Route} from 'react-router-dom';
import UnauthorizedPage from '../../pages/UnauthorizedPage';

const ProtectedRoute = (props) => {

    const { component: Component, moduleName, userModules, ...rest } = props;

    const getModuleAccess = () =>{
        const result = userModules.find(modules => modules.name === moduleName && modules.read);
        return result;
    }

    return(
        getModuleAccess() ?  
        <Route {...rest} render={(props) => (
            <Component {...props} moduleAccess={getModuleAccess()} />
        )} /> 
        : <UnauthorizedPage />
        
    ); 

}; 

export default ProtectedRoute;