import apibase from './BaseAPI';

export const getCurrentVersion = async () => {
    const url = `/version/getCurrentVersion`;
    try {
        const result = await apibase.post(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

export default {
    getCurrentVersion: getCurrentVersion,
}