import React from 'react';
import PropTypes from 'utils/propTypes';
import classNames from 'classnames';
import { Card, CardTitle, CardSubtitle, CardText, CardBody } from 'reactstrap';
import Avatar from '../Avatar';
import auditTrailComponent from '../../biz/auditTrailComponent';
import { useSelector, useDispatch } from 'react-redux';
import { logoff } from '../../redux/actions/userActions';

const UserCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  children,
  className,
  ...restProps
}) => {
  const classes = classNames('bg-gradient-theme', className);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const handleSignout = async () => {
    if (localStorage.getItem('isChecked') === 'false') {
      localStorage.clear()
    }

    if (user?._id) await auditTrailComponent.adminLog(user._id, `logoff`);
    dispatch(logoff());
    window.location = '/';
  };

  const getCurrentUser = () => {
    return user?.userName ?? '';
  };

  return (
    <Card inverse className={classes} {...restProps}>
      <CardBody className="d-flex justify-content-center align-items-center flex-column">
        <Avatar src={avatar} size={avatarSize} className="mb-2" />
        <CardTitle>{getCurrentUser()}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
        <CardText>
          <small>{text}</small>
        </CardText>
        <button className="btn btn-primary" onClick={handleSignout}>Signout</button>
      </CardBody>
      {children}
    </Card>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

UserCard.defaultProps = {
  avatarSize: 80,
};

export default UserCard;
