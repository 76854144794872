const initState = {
    _id: null,
    userName: '',
    name: '',
    level: 0,
    role: '',
    token: null,
    modules: []
};

const user = (state = initState, action) => {
    switch (action.type) {
        case 'LOGGED_IN': return action.user;
        case 'LOGGED_OUT': return initState;
        default: return state;
    }
};

export default user;
