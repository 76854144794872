const isValidEmail = (email) => {
    const emailRegexp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    return emailRegexp.test(email);
};

const isNumeric = (input) => {   
    //return (/^[0-9()-.\s]+$/.test(input));
    return (/^[0-9]*$/.test(input));
};

const isValidPhone = (phone) => {
    if(phone.startsWith('+')) return (isNumeric(phone.substr(1)) && phone.length >= 10);
    return (isNumeric(phone) && phone.length >= 10);
};

const isValidMobile = (phone) => { //019-9787070 or 019-97870703 with or without +6
    return /^((\+6)?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/.test(phone); 
};

const isValidMobileFixFormat = (phone) => { //01x-xxxxxxx / 01x-xxxxxxxx
    return /^(01)[0-46-9]\-[0-9]{7,8}$/g.test(phone)
}

const isValidSGMobileFixFormat = (phoneNumber) => { //fixed SG phone num eg: 65XXXXXXXX
    const SGPhonePattern = /^(65)[0-9]{8}$/

    return SGPhonePattern.test(phoneNumber)
}

const isValidIc=(ic)=>{
    return /^\d{6}-\d{2}-\d{4}$/.test(ic);
}

const isCompleteAddress = (address) => {
    if ((!address.line1) || (address.city === 'City') || (address.state === 'State') || (!address.postcode) || (address.country === 'Country'))
        return false;
    else return true;
};

const isDecimal = (input) =>{
    return (/^[0-9]+(\.[0-9]{1,2})?$/.test(input));
};

const isURL = (input) =>{
    return(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(input));
};

const toVideoEmbedLink = (url) =>{
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return 'https://www.youtube.com/embed/' + match[2] + '?autoplay=1'
    } else {
        return '';
    }
};

const isAlphabet = (input) => {
    return (/^[a-zA-Z]*$/.test(input));
};

const isAlphabetWithSpace = (input) => {
    return (/^\S.*[a-zA-Z'/. ]*$/.test(input));
};

const isDDMMYYYY = (input) => {// format: dd/MM/YYYY
    return(/^\d{2}\/\d{2}\/\d{4}$/.test(input));
}

export default {
    isValidEmail: isValidEmail,
    isNumeric: isNumeric,
    isValidPhone: isValidPhone,
    isValidMobile: isValidMobile,
    isValidMobileFixFormat: isValidMobileFixFormat,
    isValidSGMobileFixFormat: isValidSGMobileFixFormat,
    isValidIc:isValidIc,
    isCompleteAddress: isCompleteAddress,
    isDecimal: isDecimal,
    isURL: isURL,
    toVideoEmbedLink: toVideoEmbedLink,
    isAlphabet: isAlphabet,
    isAlphabetWithSpace: isAlphabetWithSpace,
    isDDMMYYYY: isDDMMYYYY,
};
