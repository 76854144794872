import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import userProfile from '../biz/user/userProfile';
import { useDispatch } from 'react-redux';
import { login } from '../redux/actions/userActions';
//import { useTranslation } from 'react-i18next';
//import LangSelector from '../components/LangSelector';

const AuthForm = props => {
  //const { t } = useTranslation();
  const dispatch = useDispatch();
  const [credential, setCredential] = useState({
    username: process.env.REACT_APP_DEV_MODE === `true` ? `cy2` : ``,
    password: process.env.REACT_APP_DEV_MODE === `true` ? `Atos@2005` : ``,
    isChecked: '',
    email: ''
  });
  const [err, setErr] = useState('');
  const [modal, setModal] = useState(false);
  const rotatingTextList = [{
    user_name: 'User Name',
    password: 'Password',
    forgot_password: 'Forgot Password',
    remember_me: 'Remember Me',
    signin: 'Sign in',
    back_to_signin: 'Back to Sign In',
    reset_passowrd: 'Reset Password',
    reset_instruction: 'Enter your email address and we will send you a password reset link',
    reset_note: 'You\'ll receive a reset password link in your registered mailbox shortly',
    email: 'Email'
  }, {
    user_name: 'Nama Pengguna',
    password: 'Kata Laluan',
    forgot_password: 'Terlupa Kata Laluan',
    remember_me: 'Ingati Saya',
    signin: 'Daftar Masuk',
    back_to_signin: 'Kembali ke Daftar Masuk',
    reset_passowrd: 'Tukar Kata Laluan',
    reset_instruction: 'kenal pastikan alamat emel supaya kami hantar kata laluan pautan yang baru',
    reset_note: 'Anda akan menerima kata laluan pautan yang baru sebentar lagi',
    email: 'Emel'
  }, {
    user_name: '账户',
    password: '密码',
    forgot_password: '忘记密码',
    remember_me: '记得我',
    signin: '登入',
    back_to_signin: '会到登入页面',
    reset_passowrd: '重启密码',
    reset_instruction: '附上电邮地址以便我们能送上更新密码链路',
    reset_note: '您将在之后收到我们的更新密码链路',
    email: '电邮地址'
  }];
  const [rotatingCount, setRotatingCount] = useState(0);
  const [rotatingText, setRotatingText] = useState(rotatingTextList[rotatingCount]);
  const history = useHistory();

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setRotatingCount(rotatingCount + 1);
  //   }, 4000);
  //   // clearing interval
  //   return () => clearInterval(timer);
  // });

  // useEffect(() => {
  //   setRotatingText(rotatingTextList[rotatingCount % rotatingTextList.length]);
  // }, [rotatingCount]);

  const toggle = () => {
    setModal(modal => !modal);
  }

  const isLogin = () => {
    return props.authState === STATE_LOGIN;
  }

  // const isSignup = () => {
  //   return props.authState === STATE_SIGNUP;
  // }

  const isForgot = () => {
    return props.authState === STATE_FORGOT;
  }

  const changeAuthState = authState => event => {
    event.preventDefault();
    props.onChangeAuthState(authState);
  };

  const handleChange = (event) => {
    event.persist();
    localStorage.clear();
    setCredential(credential => ({
      ...credential,
      [event.target.name]: event.target.value
    }));
  }

  const onError = (err) => {
    setErr(err);
  };

  const handleLogin = async (event) => {
    try {
      event.preventDefault();
      setErr('');

      const { username, password, isChecked } = credential;

      localStorage.setItem('username', isChecked ? username : '');
      localStorage.setItem('password', isChecked ? password : '');
      localStorage.setItem('isChecked', isChecked ? isChecked : false);

      const res = userProfile.validateLogin(credential);
      if (res.ok) {
        let user = await userProfile.login(credential.username, credential.password);
        //console.log(user);

        if (user) {
          if (user.status === 'Active') {
            let trimmedUser = {
              _id: user._id,
              userName: user.username,
              name: user.name,
              level: user.role.level,
              role: user.role.role
            };

            // localStorage.setItem('user', JSON.stringify(trimmedUser));
            // localStorage.setItem('userModules', JSON.stringify(user.role.modules));

            dispatch(login({
              ...trimmedUser,
              modules: user.role.modules,
            }));

            //window.location = '/dashboard';
            //history.pushState(null, '', '/dashboard');
            history.push('/dashboard');
          }
          else {
            onError("Login failed. This account is not authorized for login.");
          }
        }
        else {
          onError("Login failed. Please try again.");
        }
      }
      else {
        onError(res.err);
      }
    }
    catch (err) {
      if (err.response && err.response.status === 401)
        onError("Invalid username/password. Please try again");
      else
        onError(err);
    }
  };

  const handleForgot = async (event) => {
    try {
      event.preventDefault();
      setErr('');
      const res = userProfile.validateForgotPassword(credential);
      if (res.ok) {
        await userProfile.forgotPassword(credential.email);
        toggle();
      }
      else onError(res.err);
    }
    catch (err) {
      onError(err);
    }
  };

  const handleGoToLogin = (event) => {
    event.preventDefault();
    setModal(modal => !modal);
    props.onChangeAuthState(STATE_LOGIN);
  };

  const renderButtonText = () => {
    return 'Sign in';
  }

  const handlekeyEnter = (event) => {
    event.preventDefault();
    if (event.key.toLowerCase() === "enter")
      handleLogin(event)
  };

  const onChangeCheckbox = (event) => {
    event.persist();
    localStorage.clear();
    setCredential(credential => ({
      ...credential,
      [event.target.name]: event.target.checked
    }));
  }

  const {
    showLogo,
    usernameLabel,
    //usernameInputProps,
    passwordLabel,
    //passwordInputProps,
    // confirmPasswordLabel,
    // confirmPasswordInputProps,
    children,
    //onLogoClick,
  } = props;

  if (!credential.username && !credential.password) {
    if (localStorage.getItem('username') && localStorage.getItem('password')) {
      credential.username = localStorage.getItem('username');
      credential.password = localStorage.getItem('password');
      credential.isChecked = localStorage.getItem('isChecked');
    }
  }

  return (
    <Form>
      {showLogo && (
        <div className="text-center pb-4">
          <a href="#">
            <img
              src={"https://iprofstore.blob.core.windows.net/general/nextsixAdmin.png"}
              className="rounded login-logo "
              // style={{ width: 400, height: 100, cursor: 'pointer' }}
              alt="logo"
            //onClick={onLogoClick}
            />
          </a>
          {/* <label><strong>&nbsp;.Admin</strong></label> */}
        </div>
      )}

      {isLogin() && <>
        {localStorage.getItem('isChecked') === true ?
          <>
            <FormGroup>
              <Label for={usernameLabel}>{rotatingText.user_name}</Label>
              {/* <Input {...usernameInputProps} /> */}
              <Input type="text" className="form-control" placeholder={rotatingText.user_name} name="username" value={credential.username || localStorage.getItem('username')} onChange={handleChange} onKeyUp={handlekeyEnter} id="username" />
            </FormGroup>
            <FormGroup>
              <Label for={passwordLabel}>{rotatingText.password}</Label>
              {/* <Input {...passwordInputProps} /> */}
              <Input type="password" className="form-control" placeholder={rotatingText.password} name="password" value={credential.password || localStorage.getItem('password')} onChange={handleChange} onKeyUp={handlekeyEnter} />
            </FormGroup>
            <FormGroup>
              <Label for="rememberMe" >{rotatingText.remember_me}</Label>
              <Input type="checkbox" value="lsRememberMe" id="rememberMe" style={{ marginLeft: "10px" }} name="isChecked" onChange={onChangeCheckbox} checked={localStorage.getItem('isChecked') ? Boolean(localStorage.getItem('isChecked')) : credential.isChecked} onKeyUp={handlekeyEnter} />
            </FormGroup>
          </>
          :
          <>
            <FormGroup>
              <Label for={usernameLabel}>{rotatingText.user_name}</Label>
              {/* <Input {...usernameInputProps} /> */}
              <Input type="text" className="form-control" placeholder={rotatingText.user_name} name="username" value={credential.username} onChange={handleChange} onKeyUp={handlekeyEnter} id="username" />
            </FormGroup>
            <FormGroup>
              <Label for={passwordLabel}>{rotatingText.password}</Label>
              {/* <Input {...passwordInputProps} /> */}
              <Input type="password" className="form-control" placeholder={rotatingText.password} name="password" value={credential.password} onChange={handleChange} onKeyUp={handlekeyEnter} />
            </FormGroup>
            <FormGroup>
              <Label for="rememberMe" >{rotatingText.remember_me}</Label>
              <Input type="checkbox" value="lsRememberMe" id="rememberMe" style={{ marginLeft: "10px" }} name="isChecked" onChange={onChangeCheckbox} checked={credential.isChecked} onKeyUp={handlekeyEnter} />

            </FormGroup>
          </>
        }
      </>
      }

      {isForgot() && <>
        <p>{rotatingText.reset_instruction}.</p>
        <FormGroup>
          <Input type="text" className="form-control" placeholder={rotatingText.email} name="email" value={credential.email} onChange={handleChange} />
        </FormGroup>

        <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
          <ModalBody>
            You'll receive a reset password link in your registered mailbox shortly.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleGoToLogin}>OK</Button>
          </ModalFooter>
        </Modal>
      </>
      }
      {
        err && <div className="form-group">
          <p className="text-danger">{err}</p>
        </div>
      }
      <hr />
      {isLogin() &&
        <>
          <Button
            size="lg"
            className="bg-gradient-theme-left border-0"
            block
            onClick={handleLogin}>
            {rotatingText.signin}
          </Button>
          <Button color="link" onClick={changeAuthState(STATE_FORGOT)}>{rotatingText.forgot_password}</Button>
          {/* <LangSelector /> */}
        </>
      }

      {isForgot() && <>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={handleForgot}>
          {rotatingText.reset_passowrd}
        </Button>
        <Button color="link" onClick={changeAuthState(STATE_LOGIN)}>{rotatingText.back_to_signin}</Button>
      </>
      }
      {children}
    </Form>
  );

}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';
export const STATE_FORGOT = 'FORGOT';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP, STATE_FORGOT]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
  onChangeAuthState: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: "User Name",
  usernameInputProps: {
    type: 'text',
    placeholder: 'User Name',
  },
  passwordLabel: "Password",
  passwordInputProps: {
    type: 'password',
    placeholder: 'Password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => { },
};

export default AuthForm;