import apibase from './BaseAPI';

const adminLatest = async (search, paging) => {
    const params = {
        search: search,
        paging: paging
    }

    const res = await apibase.post(`/auditTrails/admin/latest`, params);//
    return res.data;
};

const agentLatest = async (search, paging) => {
    const params = {
        search: search,
        paging: paging
    }

    const res = await apibase.post(`/auditTrails/agent/latest`, params);
    return res.data;
};

const adminLog = async (adminId, action) => {
    const url = `/auditTrails/admin/${adminId}/log/${action.toLowerCase()}`;
    const res = await apibase.post(url);//
    return res.data;
}

export default {
    adminLatest,
    agentLatest,
    adminLog,
};

