const SetupData = {
  mapAPIkey: ["AIza-no-longer-in-used"],
  mapLanguage: ["en"],
  mapRegion: ["MY"],
  merchantTypes: ['Law Firm', 'Banker', 'Interior Design', 'Home Renovation'],
  referralProgramCommissionType: ["%", "RM"],
  referralStatus: ["New", "Accepted", "Completed", "Approved", "Declined"],
  gender: ["Male", "Female"],
  services: [
    {
      'type': 'Law Firm',
      'category': ['Property & Real Estate Law', 'Loan, Banking & Finance Law', 'Corporate Real Estate Law', 'Divorce & Family Law', 'Employment Law',
        'Wills, Probate & Estate Administration Law', 'Dispute Resolution & Civil Litigation Law', 'Accident & Medical Negligence Law', 'Corporate & Commercial Law',
        'Intellectual Property & Technology Law', 'Others'],
      'referralProgramService': ['Sales & Purchase Agreement', 'Loan Agreement', 'Will Writing & Trust Services'],
    }, {
      'type': 'Banker',
      'category': ['Mortgage Loan', 'Hire Purchase Loan', 'Business Loan', 'Personal Loan', 'Investment', 'Others'],
      'referralProgramService': ['Mortgage Loan'],
    }, {
      'type': 'Interior Design',
      'category': ['Interior Design', 'Others']
    }, {
      'type': 'Home Renovation',
      'category': ['Masonry', 'Foundation and Structure', 'Carpentry', 'Demolition', 'Metal', 'Plaster Ceiling', 'Electrical and Lighting', 'Flooring', 'Painting',
        'Rooding', 'Plumbing', 'Door or Window', 'Glass Work', 'Drywall Partition', 'Others'],
      'referralProgramService': ['Other'],
    }
  ],
  states: ['Johor', 'Kedah', 'Kelantan', 'Wilayah Persekutuan Kuala Lumpur', 'Wilayah Persekutuan Labuan', 'Melaka', 'Negeri Sembilan', 'Pahang',
    'Penang', 'Perak', 'Perlis', 'Wilayah Persekutuan Putrajaya', 'Sabah', 'Sarawak', 'Selangor', 'Terengganu'],
  countries: ['Malaysia'],
  agentTypes: ['Normal'],
  ranks: [1, 2, 3, 4, 5],
  ratings: [1, 2, 3, 4, 5],
  product: {
    categories: ['Foods', 'Groceries', 'Toiletries']
  },
  merchant: {
    categories: ["Grocery", "Pharmacy", "Speedmart"],
    status: ["Verified", "Active", "Inactive", "Suspended"]
  },
  agent: {
    active: ["In Service", "Temporary Out of Service", "Disabled"]
  },
  property: {
    categories: ['Commercial', 'Residential'],
    types: ['Apartment', 'Flat', 'Condominium', 'Serviced Residence', '1-sty Terrace/Link House', '2-sty Terrace/Link House',
      '3-sty Terrace/Link House', '4-sty Terrace/Link House', '2 & 3 Sty Terrace/Link House', '1.5-sty Terrace/Link House',
      '2.5-sty Terrace/Link House', '3.5-sty Terrace/Link House', '4.5-sty Terrace/Link House', 'Double Storey Superlink Homes',
      '3 Storey Superlink Homes', 'Townhouse', 'Cluster House', "Bungalow", 'Bungalow Land', 'Semi-detached House', 'Villa',
      '3 Storey Semi-detached Homes', 'Residential Land', 'Office', 'Shop', 'Shop Office', 'Retail Office', 'Retail Space', 'SOFO',
      'SOHO', 'SOVO', 'Commercial Bungalow', 'Commercial Semi-D', 'Designer Suites', 'Business Center', 'Hotel/Resort', 'Commercial Land',
      'Suites', 'Luxury Suites', 'Factory', 'Semi-D Factory', 'Detached Factory', 'Link Factory', 'Warehouse', 'Light Industrial', 'Industrial Land',
      'Agricultural Land'],
    unitTypes: ['Corner', 'Duplex', 'Endlot', 'Intermediate', 'Penthouse', 'SOHO', 'Studio', 'Triplex', 'Unknown'],
    titles: ['Aparment/Flat', 'Condo/Serviced Residence', 'SOHO', "SOVO", "Semi-D/Bungalow", "Terrace/Link/Townhouse", "Residential Land",
      "Shop/Office/Retail Space", "Hotel/Resort", "Commercial Land", "Suites", "Luxury Suites", "Factory/Warehouse", "Industrial Land",
      "Agricultural Land"],
    tenures: ['Freehold', 'Leasehold', 'Lease Tenancy', 'Malay Reserved Land', 'Unknown'],
    furnishings: ['Fully Furnished', 'Partly Furnished', 'Unfurnished', 'Unknown'],
    for: ['Buy', 'Rent'],
    forProject: ['Buy'],
    sizeMeasureCodes: ['Square Feet', 'Square Meters', 'Acres', 'Hectare', 'Unknown'],
    titleTypes: ['Individual', 'Strata', 'Unknown'],
    landTitleTypes: ['Agricultural', 'Commercial', 'Industrial', 'Residential', 'Unknown'],
    directions: ['North', 'North East', 'East', 'South East', 'South', 'South West', 'West', 'North West', 'Unknown'],
    occupies: ['Owner Occupied', 'Tenanted', 'Vacant', 'Unknown'],
    beds: ['Studio', '1', '1+1', '1+2', '2', '2+1', '2+2', '3', '3+1', '3+2', '4', '4+1', '4+2', '5', '5+1', '5+2', '6', '6+', '6+1', '7',
      '7+', '7+1', '8', '8+', '8+1', '9', '9+', '9+1', '10', '10+', '10+1', '11', '11+', '11+1', '12', '12+', '12+1', '13', '13+',
      '13+1', '14', '14+', '14+1', '15', '15+1', '16', '16+', '17', '17+', '18', '18+', '19', '20 Above'],
    facilities: ['BBQ', 'Business Centre', 'Club House', 'Parking', 'Gymnasium', 'Jacuzzi', 'Jogging Track', 'Mini Market', 'Nursery', 'Playground',
      'Salon', 'Sauna', 'Squash Court', 'Swimming Pool', 'Wading Pool', 'Tennis Court', '24hr Security', 'Cafeteria'
    ],
    propertyListing: [
      { label: 'Subsale', value: 'Subsale' },
      { label: 'New(Developer Unit)', value: 'Projects' }
    ],
    rentType: ["Entire Place", "Room Only"]
  },
  user: {
    levels: [2, 1],
    statuses: ["Active", "Inactive", "Suspended"]
  },
  role: ["REN", "PEA", "REA"],
  auction: {
    bidderType: ["Individual", "Company"],
    assignee: [
      "PG ActFast Auction Sdn Bhd"
    ],
    tenure: ["Freehold", "Leasehold"],
    titleType: ["Master", "Strata"],
    restriction: ["Bumi-Lot", "Non-Bumi", "Native Land", "Indian Quota"],
    lotType: ["Intermediate", "Corner Lot", "End Lot"]
  },
  redeemItem: {
    types: ['Cash'],
    redeemUnits: ['Point']
  },
  profile: {
    imgURL: "https://iprofstore.blob.core.windows.net/general/agentDefault.png",
    onlineImgURL: {
      star1: "https://iprofstore.blob.core.windows.net/general/agent-online-1-stars.png",
      star2: "https://iprofstore.blob.core.windows.net/general/agent-online-2-stars.png",
      star3: "https://iprofstore.blob.core.windows.net/general/agent-online-3-stars.png",
      star4: "https://iprofstore.blob.core.windows.net/general/agent-online-4-stars.png",
      star5: "https://iprofstore.blob.core.windows.net/general/agent-online-5-stars.png"
    },
    offlineImgURL: {
      star1: "https://iprofstore.blob.core.windows.net/general/agent-offline-1-stars.png",
      star2: "https://iprofstore.blob.core.windows.net/general/agent-offline-2-stars.png",
      star3: "https://iprofstore.blob.core.windows.net/general/agent-offline-3-stars.png",
      star4: "https://iprofstore.blob.core.windows.net/general/agent-offline-4-stars.png",
      star5: "https://iprofstore.blob.core.windows.net/general/agent-offline-5-stars.png"
    }
  },
  defaultHouse: "https://iprofstore.blob.core.windows.net/general/house.jpg",
  mapAgentImage: {
    width: 120,
    height: 120,
    offlineOpacity: 0.5
  },
  files: {
    apiKey: "VBKV2rL8uDSLzMBsnDGRus5ZTe9M75rDgZr8fMRmgzHsX9uapZDG4jjM94q7JrmQ"
  },
  searchStatus: {
    luckyDraw: ["New", "Drawn", "Claimed"],
    luckyDrawPrize: ["Active", "Inactive"],
    featureProperty: ["Active", "Inactive"],
    announcement: ["New", "In Progress", "Completed", "Terminate"],
    assessment: ["The Roof Realty", "Public"],
    attendance: ["Complete", "Incomplete"],
    agentApplication: ["Pending", "Approved", "Rejected"],
    auctionProperty: ["Submitted", "Approved", "Rejected", "Sold", "No Deal"],
    workshop: ["TRR", "Public"],
    package: ["Kick Starter Pack (Free)", "Essential Pack", "Pro Pack", "Campaign Pack"],
    property: ["Active", "Inactive"],
    agentPlotting: ["With Plotting", "Without Plotting"],
    agentActive: ["In Service", "Temporary Out of Service", "Disabled"],
    appointment: ['New', 'Accepted', 'Rejected', 'Cancelled', 'Completed', 'Dropped', 'Expired']
  },
  advertisement: {
    types: ["Developer", "Project", "Campaign"],
    category: ["Website Banner", "Mobile Banner"]
  },
  announcement: {
    groupType: ["System"],
    targetGroup: ["User", "Agent", "Guest"]
  },
  assessment: {
    targetGroup: ["The Roof Realty", "Public", "All Agents"],
  },
  workshop: {
    companyName: ["The Roof Realty", "Public", "All Agents"]
  },
  sidebarInput: [
    { id: 'agents', description: 'Agents' },
    { id: 'appusers', description: 'App Users' },
    { id: 'dashboard', description: 'Dashboard' },
    // { id: 'products', description: 'Products' },
    { id: 'properties', description: 'Properties' },
    { id: 'roles', description: 'Administration User Roles' },
    { id: 'userprofiles', description: 'Administration User Profiles' },
    { id: 'salequotation', description: 'Sale Quotation' },
    { id: 'paymentvoucher', description: 'Payment Voucher' },
    { id: 'receipt', description: 'Receipt' },
    { id: 'invoice', description: 'Invoice' },
    // { id: 'shops', description: 'Shops' },
    { id: 'guidance', description: 'Guidance' },
    { id: 'master/property', description: 'Property Master' },
    { id: 'advertisements', description: 'Advertisement' },
    { id: 'mergeMasterProperty', description: 'Merge Property Master' },
    { id: 'feature/property', description: 'Feature Properties' },
    { id: 'luckydrawprize', description: 'Lucky Draw Prize List' },
    { id: 'announcement', description: 'Announcement' },
    { id: 'challenges', description: 'Challenges' },
    { id: 'videos', description: 'Videos' },
    { id: 'agents/master/property', description: 'New Property Master request' },
    { id: 'agents/role', description: 'REN ID Request' },
    { id: 'agents/tnc', description: 'Terms Of Use Update' },
    // { id: 'agents/specialists', description: 'Specialists' },
    // { id: 'areaspecialists', description: 'Area Specialists' },
    // { id: 'condospecialists', description: 'Condo Specialists' },
    { id: 'developers', description: 'Developers' },
    { id: 'projects', description: 'Projects' },
    { id: 'bidnow/roles', description: 'BidNow Admin User Roles' },
    { id: 'bidnow/userprofiles', description: 'BidNow Admin User Profiles' },
    { id: 'bidnow/auction/properties', description: 'Auction Properties' },
    { id: 'bidnow/auction/process', description: 'Auction Process' },
    { id: 'bidnow/auction/bidders', description: 'Auction Bidders' },
    { id: 'luckydraw', description: 'Lucky Draw' },
    { id: 'services', description: 'Services' },
    { id: 'about', description: 'About' },
    { id: 'assessment', description: 'Assessment' },
    { id: 'agentapplication', description: 'Agent Application' },
    { id: 'cobroke', description: 'Co-Broke' },
    { id: 'company', description: 'Company' },
    { id: 'agentplotting', description: 'Agent Plotting' },
    { id: 'flagmanagement', description: 'Flag Management' },
    { id: 'specialists', description: 'Specialists' },
    { id: 'areaspecialistsoverview', description: 'Area Specialists Overview' },
    { id: 'agentonlinereport', description: 'Agent Online Report Summary' },
    { id: 'audittrails', description: 'Audit Trails' },
    { id: 'datasummaryreport', description: 'Data Summary Report' },
    { id: 'searchlog', description: 'Search Log' },
    { id: 'propertysummaryreport', description: 'Property Summary Report' },
    // { id: 'referrals', description: 'Referral Program' },
    // { id: 'merchants', description: 'Merchants' },
    { id: 'merchants/application', description: 'Merchants Application' },
    { id: 'merchants/profile', description: 'Merchants Profile' },
    { id: 'enquiry', description: 'Merchants Enquiries' },
    { id: 'listingmigration', description: 'Listing Migration' },
    { id: 'listingmigrationLog', description: 'Listing Migration Log' },
    { id: 'appointment', description: 'Appointment' },
    { id: 'enquiryReminder', description: 'Enquiry Reminder' },
    { id: 'showcase', description: 'Showcase' },
    { id: 'bdProfile', description: 'BD Profile' },
    { id: 'bdSummary', description: 'BD Summary' },
    { id: 'bdSetting', description: 'BD Setting' },
    { id: 'grouplisting', description: 'Group Listing' },
    { id: 'grouplistingapplication', description: 'Group Listing Application' },
    { id: 'meetingroom', description: 'Meeting Room' },
    { id: 'meetingroomapplication', description: 'Meeting Room Application' },
    { id: 'paymentgateway/platform', description: 'Payment Gateway Platform' },
    { id: 'erp213/log', description: 'Erp213 Log' },
  ],
  eCard: {
    apiKey: "75SbwJGNUeCFccH3vCWbTe83PdqISDjl"
  },
  trrCompany: "The Roof Realty",
  sortColumn: {
    agentApplication: [
      { label: "Name", value: "name" },
      { label: "REN Id", value: "renId" },
      { label: "Contact No.", value: "mobile" },
      { label: "Email", value: "email" },
      { label: "Package", value: "package.name" },
      { label: "Applied Date", value: "appliedOn" },
      { label: "Status", value: "status" },
      { label: "Processed Date", value: "processedOn" },
      { label: "Processed by", value: "processedBy" },
    ],
  },
  assignTo: ["Agent", "Public", "AgentAndPublic"],
  isStateAreaUpdated: [
    { label: "Both Specialized state and area filled", value: "isUpdated" },
    { label: "Both Specialized state and area empty", value: "isNotUpdated" },
    { label: "Specialized State filled only", value: "stateOnly" },
  ],
  merchantCoverage: [
    { label: "Johor", value: "Johor" },
    { label: "Kedah", value: "Kedah" },
    { label: "Kelantan", value: "Kelantan" },
    { label: "Melaka", value: "Melaka" },
    { label: "Negeri Sembilan", value: "Negeri Sembilan" },
    { label: "Pahang", value: "Pahang" },
    { label: "Penang", value: "Penang" },
    { label: "Perak", value: "Perak" },
    { label: "Perlis", value: "Perlis" },
    { label: "Sabah", value: "Sabah" },
    { label: "Sarawak", value: "Sarawak" },
    { label: "Terengganu", value: "Terengganu" },
    { label: "Selangor", value: "Selangor" },
    { label: "Wilayah Persekutuan Kuala Lumpur", value: "Wilayah Persekutuan Kuala Lumpur" },
    { label: "Wilayah Persekutuan Labuan", value: "Wilayah Persekutuan Labuan" },
    { label: "Wilayah Persekutuan Putrajaya", value: "Wilayah Persekutuan Putrajaya" },
  ],
  agentPackageDuration: [
    { display: "1 Month", value: 1 },
    { display: "2 Months", value: 2 },
    { display: "3 Months", value: 3 },
    { display: "4 Months", value: 4 },
    { display: "5 Months", value: 5 },
    { display: "6 Months", value: 6 },
    { display: "7 Months", value: 7 },
    { display: "8 Months", value: 8 },
    { display: "9 Months", value: 9 },
    { display: "10 Months", value: 10 },
    { display: "11 Months", value: 11 },
    { display: "12 Months", value: 12 },
    { display: "13 Months", value: 13 },
    { display: "24 Months", value: 24 }
  ],
  campaignDuration: [
    { display: "3 Months", value: 3 },
    { display: "6 Months", value: 6 }
  ],
  showcaseType: [
    "WTB", "WTR", "Owner Listing (Sale)", "Owner Listing (Rent)"
  ],
  isStateAreaMismatch: [
    { label: "Both specialized state & area mismatch", value: "bothMismatch" },
    { label: "Specialized state mismatch only", value: "stateMismatchOnly" },
    { label: "Specialized area mismatch only", value: "areaMismatchOnly" },
  ],
  stateAreaMismatchDownloadDropdown: [
    { label: "Both Specialized State & Area Mismatch List", value: "both" },
    { label: "Specialized State Mismatch List", value: "stateOnly" },
    { label: "Specialized Area Mismatch List", value: "areaOnly" },
  ],
  serviceMerchant: {
    status: [
      { value: "Verified", label: "Verified" },
      { value: "Active", label: "Active" },
      { value: "Inactive", label: "Inactive" },
      { value: "Suspended", label: "Suspended" },
    ],
  },
  serviceEnquiries: {
    status: [
      { value: "New", label: "New" },
      { value: "In Progress", label: "In Progress" },
      { value: "Rejected", label: "Rejected" },
      { value: "Cancelled", label: "Cancelled" },
      { value: "Completed", label: "Completed" },
    ],

    areaToDesign: [
      { value: 'Entire House', label: 'Entire House' },
      { value: 'Bedrooms', label: 'Bedrooms' },
      { value: 'Kitchen', label: 'Kitchen' },
      { value: 'Living', label: 'Living' },
      { value: 'Bathrooms', label: 'Bathrooms' },
      { value: 'Others', label: 'Others' }
    ],

    budget: [
      { value: '< 50,000', label: '< 50,000' },
      { value: '50,000-100,000', label: '50,000-100,000' },
      { value: '100,000-200,000', label: '100,000-200,000' },
      { value: '200,000-300,000', label: '200,000-300,000' },
      { value: '300,000-400,000', label: '300,000-400,000' },
      { value: '400,000-500,000', label: '400,000-500,000' },
      { value: '> 500,000', label: '> 500,000' }
    ],

    interior: [
      { value: 'Modern', label: 'Modern' },
      { value: 'Classic', label: 'Classic' },
      { value: 'Traditional', label: 'Traditional' },
      { value: 'Country', label: 'Country' },
      { value: 'Others', label: 'Others' }
    ],
    propertySize: [
      { value: '< 1000sqft', label: '< 1000sqft' },
      { value: '1000-2000sqft', label: '1000-2000sqft' },
      { value: '2000-3000sqft', label: '2000-3000sqft' },
      { value: '3000-4000sqft', label: '3000-4000sqft' },
      { value: '> 4000sqft', label: '> 4000sqft' }
    ],

    propertyType: [
      'Apartment', 'Flat', 'Condominium', 'Serviced Residence', '1-sty Terrace/Link House', '2-sty Terrace/Link House',
      '3-sty Terrace/Link House', '4-sty Terrace/Link House', '2 & 3 Sty Terrace/Link House', '1.5-sty Terrace/Link House',
      '2.5-sty Terrace/Link House', '3.5-sty Terrace/Link House', '4.5-sty Terrace/Link House', 'Double Storey Superlink Homes',
      '3 Storey Superlink Homes', 'Townhouse', 'Cluster House', "Bungalow", 'Bungalow Land', 'Semi-detached House', 'Villa',
      '3 Storey Semi-detached Homes', 'Office', 'Shop', 'Shop Office', 'Retail Office', 'Retail Space', 'SOFO',
      'SOHO', 'SOVO', 'Commercial Bungalow', 'Commercial Semi-D', 'Designer Suites', 'Business Center', 'Hotel/Resort',
      'Suites', 'Luxury Suites', 'Factory', 'Semi-D Factory', 'Detached Factory', 'Link Factory', 'Warehouse', 'Light Industrial'
    ]
  },
  paymentGateway: {
    platform: ["iPay88", "Payex"],
    key: [
      "payment_gateway_nextsix",
      "payment_gateway_iroomz",
      "payment_gateway_iproforma"
    ],
  }
}

export default SetupData;

